import React, { useEffect, useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
const tz = 'America/New_York';
dayjs.tz.setDefault(tz);
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import AddInfoSessionModal from '../../../components/Modals/AddInfoSession/AddInfoSession';
import GenericDialog from '../../../components/Dialog/genericDialog/genericDialog';
import {
  IInfoSessionsMetaInfo,
  getInfoSessionsByQuery,
  searchInfoSession,
} from '../../../services/infoSession.service';
import { InfoSessionModel } from '../../../store/infoSession/infoSessionModel';
import { deleteInfoSession } from '../../../services/infoSession.service';
import CustomTable from '../../../components/CustomTable';
import { InfoSessionListStatuses } from '../client/utils';
import { COLORS } from '../../../utils/colors';
//eslint-disable-next-line
import { WriteModeEnum } from '../../../utils/writeModeEnum';
import { useNavigate } from 'react-router-dom';
import { CreateColumnHeader } from '../../../components/CreateColumnHeader/CreateColumnHeader';
import { CreateColumnCell } from '../../../components/CreateColumnCell/CreateColumnCell';

const InfoSessionList = (props: any) => {
  const navigate = useNavigate();
  const [infoSessionsList, setInfoSessionsList] = React.useState<Array<InfoSessionModel>>([]);
  const [infoSessionModal, setInfoSessionModal] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [deleteSessionId, setDeleteSessionId] = useState(String);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [sortModel, setSortModel] = useState([]);
  const [editMode, setEditMode] = useState({
    isEditMode: false,
    editData: {
      title: '',
      location: '',
      description: '',
      dateTime: '',
      meetingLink: '',
      customUrlName: '',
      repeatWeekly: false,
      repeatWeeks: null,
      id: '',
    },
  });

  const PAGE_SIZE = 10;
  const [activeIds, setActiveIds] = useState<number[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [infoSessionMetaInfo, setInfoSessionMetaInfo] = React.useState<IInfoSessionsMetaInfo>();
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });

  const [searchPaginationModel, setSearchPaginationModel] = React.useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });

  const [loading, setLoading] = useState(false);
  const [isSearchMode, setIsSearchMode] = React.useState(false);

  const currentTime = moment.tz('America/New_York');

  const getInfoSessionList = async () => {
    try {
      setLoading(true);
      setIsSearchMode(false);
      setSearchQuery('');

      const unsubscribe = getInfoSessionsByQuery(
        paginationModel.page + 1,
        sortModel,
        (result: any) => {
          if (result?.infoSessions.length) {
            setInfoSessionMetaInfo(result);
            const sessionDateTimeList = result?.infoSessions.map((item: any) => {
              return {
                ...item,
                dateTime: new Date(item.dateTime).toISOString(),
              };
            });

            setInfoSessionsList([...sessionDateTimeList]);
          } else {
            setInfoSessionMetaInfo(result);
            setInfoSessionsList([]);
          }
          setLoading(false);
        },
        activeIds
      );

      return () => {
        unsubscribe();
      };
    } catch (err: any) {
      toast.error(err?.response?.data?.message || err?.message);
      setLoading(false);
    }
  };

  const handleAddInfoSession = () => {
    setShouldFetch(true);
    getInfoSessionList();
  };

  const handleDelete = async () => {
    try {
      const result: any = await deleteInfoSession({ id: deleteSessionId });

      if (result.success) {
        getInfoSessionList();
        toast.success(result.message);
      } else {
        toast.error('Error deleting InfoSession!');
      }
    } catch (err: any) {
      toast.error(err?.response?.data?.message || err?.message);
    }
  };

  useEffect(() => {
    if (!isSearchMode) {
      getInfoSessionList();
    }
    // eslint-disable-next-line
  }, [props, shouldFetch, paginationModel.page, activeIds]);

  const handleSearch = async () => {
    if (searchQuery) {
      setLoading(true);
      setIsSearchMode(true);
      const result = await searchInfoSession(
        searchQuery.toLowerCase(),
        searchPaginationModel.page + 1,
        sortModel,
        activeIds
      );

      if (result?.infoSessions.length) {
        setInfoSessionMetaInfo(result);
        const sessionDateTimeList = result?.infoSessions.map((item: any) => {
          return {
            ...item,
            dateTime: new Date(item.dateTime).toISOString(),
          };
        });

        setInfoSessionsList([...sessionDateTimeList]);
      } else {
        setInfoSessionMetaInfo(result);
        setInfoSessionsList([]);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSearchMode) {
      handleSearch();
    } else {
      getInfoSessionList();
    }
    // eslint-disable-next-line
  }, [searchPaginationModel.page, activeIds, sortModel]);

  useEffect(() => {
    if (!searchQuery) getInfoSessionList();
    // eslint-disable-next-line
  }, [searchQuery]);

  const columns = [
    {
      field: 'hra',
      headerName: 'HRA Liaison Site',
      type: 'string',
      sortable: true,
      width: 250,
    },
    {
      field: 'location',
      headerName: 'Location',
      type: 'string',
      sortable: true,
      width: 250,
    },
    {
      field: 'title',
      headerName: 'Program',
      type: 'string',
      sortable: true,
      width: 250,
    },
    {
      field: 'dateTime',
      headerName: 'Date & Time',
      type: 'string',
      sortable: true,
      width: 250,
      renderCell: (params: any) => dayjs(params.row.dateTime).tz('America/New_York').format('MMMM DD, YYYY, hh:mm A'),
    },
    {
      field: 'sessionType',
      headerName: 'Type of Session',
      type: 'string',
      sortable: true,
      width: 150,
    },
    {
      field: 'numClients',
      headerName: 'No. of Clients',
      type: 'number',
      sortable: true,
      width: 250,
      renderHeader: (params: any) => {
        return CreateColumnHeader(params.colDef.headerName);
      },
      renderCell: (params: any) => {
        return CreateColumnCell(
          params.row.numClients || 0,
          COLORS.palette.white,
          undefined,
          2,
          COLORS.palette.lightBlue,
          COLORS.palette.blue
        );
      },
    },
    {
      headerName: 'Actions',
      sortable: false,
      width: 200,
      renderCell: (params: any) => (
        <Box display={'flex'} gap={2}>
          <Tooltip title='Edit' arrow>
            <IconButton
              color='primary'
              onClick={(e) => {
                e.stopPropagation();
                setEditMode({ ...editMode, isEditMode: true, editData: params.row });
                setInfoSessionModal(true);
              }}
              disabled={moment(params.row.dateTime).tz('America/New_York').isBefore(currentTime)}>
              <EditIcon
                style={{
                  color: moment(params.row.dateTime).tz('America/New_York').isBefore(currentTime)
                    ? 'grey'
                    : COLORS.theme.primaryColor,
                }}
              />
            </IconButton>
          </Tooltip>

          <Tooltip title='Delete' arrow>
            <IconButton
              color='error'
              onClick={(e) => {
                e.stopPropagation();
                setDeleteSessionId(params.row.id);
                setDeleteAlert(true);
              }}
              disabled={moment(params.row.dateTime).tz('America/New_York').isBefore(currentTime)}>
              <DeleteIcon
                style={{
                  color: moment(params.row.dateTime).tz('America/New_York').isBefore(currentTime)
                    ? 'grey'
                    : COLORS.palette.red,
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handleSortModelChange = (newSortModel: any) => {
    setSortModel(newSortModel);
  };

  return (
    <Box>
      <CustomTable
        columns={columns}
        hideSearch={false}
        rows={infoSessionsList.length ? infoSessionsList : []}
        filterList={InfoSessionListStatuses}
        loading={loading}
        onFilterListChange={(list: any) => setActiveIds(list)}
        onPaginationModelChange={isSearchMode ? setSearchPaginationModel : setPaginationModel}
        paginationModel={isSearchMode ? searchPaginationModel : paginationModel}
        primaryButtonLable='Create Info Session'
        primaryButtonAction={() => {
          setEditMode({ ...editMode, isEditMode: false });
          setInfoSessionModal(true);
        }}
        rowCount={infoSessionMetaInfo?.totalRecords || 0}
        onRowClick={(params: any) => {
          navigate('../info-session-info', {
            state: { infoSessionId: params.row.id },
          });
        }}
        title='Info Session Schedule'
        onChangeSearchQuery={(query: string) => {
          setSearchQuery(query);
        }}
        handleSearch={() => handleSearch()}
        searchQuery={searchQuery}
        onSortModelChange={handleSortModelChange}
        sortModel={sortModel}
      />

      <AddInfoSessionModal
        open={infoSessionModal}
        data={editMode.isEditMode ? editMode.editData : null}
        onClose={() => setInfoSessionModal(false)}
        mode={editMode.isEditMode ? WriteModeEnum.EDIT : WriteModeEnum.CREATE}
        onAddInfoSession={handleAddInfoSession}
      />

      <GenericDialog
        onClose={() => setDeleteAlert(false)}
        dialogTitle='Are you sure?'
        agreeBtnText='Delete'
        disagreeBtnText='Cancel'
        agreeFunc={() => handleDelete()}
        open={deleteAlert}
      />
    </Box>
  );
};

export default InfoSessionList;
