import React, { useEffect, useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import GenericDialog from '../../../components/Dialog/genericDialog/genericDialog';
import CustomTable from '../../../components/CustomTable';
import { shouldShowButton } from '../dashboard/dashboardAccessControl';
import { UserModel } from '../../../store/user/userModel';
import { useAppSelector } from '../../../store/store';
import { COLORS } from '../../../utils/colors';
import {
  addNewJobOpportunity,
  deleteJobOpportunity,
  getJobList,
  searchJobOpportunity,
} from '../../../services/employmentModule.service';
import { IJobInfo, IJobMetaInfo } from '../../../store/employmentModule/employmentModal';
import { CreateColumnCell } from '../../../components/CreateColumnCell/CreateColumnCell';
import { CreateColumnHeader } from '../../../components/CreateColumnHeader/CreateColumnHeader';
import AddJob from '../../../components/Modals/AddJob/AddJob';
import { ContentCopy, DeleteOutline, EditOutlined } from '@mui/icons-material';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import JobInterviewScheduleModal from '../../../components/Modals/JobInterviewScheduleModal/JobInterviewScheduleModal';
import { useNavigate } from 'react-router-dom';
import { jobListStatuses } from '../client/utils';

const Job = (props: any) => {
  const navigate = useNavigate();
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const [jobList, setJobList] = useState<IJobInfo[]>([]);
  const [jobModal, setJobModal] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [selectedJob, setSelectedJob] = useState<IJobInfo>();
  const [shouldFetch, setShouldFetch] = useState<boolean>(false);
  const [showInterviewScheduleModal, setShowInterviewScheduleModal] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [sortModel, setSortModel] = useState([]);

  const PAGE_SIZE = 10;
  const [searchQuery, setSearchQuery] = useState<string>('');
  // const [jobMetaInfo, setJobMetaInfo] = useState<IJobMetaInfo>();
  const [jobMetaInfo, setJobMetaInfo] = useState<any>();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const [searchPaginationModel, setSearchPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeIds, setActiveIds] = useState<number[]>([]);

  const handleDelete = async () => {
    try {
      setLoading(true);
      await deleteJobOpportunity(selectedJob?.id || '');
      setLoading(false);
      fetchJobList();
    } catch (err: any) {
      console.error(err?.response?.data?.message || err?.message);
    }
  };

  const handleDuplicate = async (job: IJobInfo) => {
    try {
      let payload: any = job;
      payload.createdAt = dayjs().toISOString();
      payload.updatedAt = dayjs().toISOString();
      delete payload?.id;
      setLoading(true);
      await addNewJobOpportunity(payload);
      setLoading(false);
      fetchJobList();
    } catch (err: any) {
      console.error(err?.response?.data?.message || err?.message);
    }
  };
  const fetchJobList = async () => {
    setLoading(true);
    setIsSearchMode(false);
    setSearchQuery('');
    getJobList(paginationModel.page + 1, activeIds, sortModel, (result: IJobMetaInfo) => {
      if (result.jobOpportunities.length > 0) {
        setJobMetaInfo(result);
        setJobList(result.jobOpportunities);
      } else {
        setJobMetaInfo(result);
        setJobList([]);
      }
      setLoading(false);
    });
  };

  useEffect(
    () => {
      fetchJobList();
    },
    // eslint-disable-next-line
    [editMode, props, shouldFetch, paginationModel.page, activeIds, sortModel]
  );

  const handleWriteCurriculum = () => {
    setShouldFetch(true);
    fetchJobList();
  };

  const handleSearch = async () => {
    if (searchQuery) {
      setLoading(true);
      setIsSearchMode(true);
      const result = await searchJobOpportunity(searchQuery.toLowerCase(), searchPaginationModel.page + 1, activeIds);

      if (result?.jobOpportunities.length) {
        setJobMetaInfo(result);
        setJobList(result?.jobOpportunities);
      } else {
        setJobMetaInfo(result);
        setJobList([]);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line
  }, [searchPaginationModel.page, activeIds]);

  useEffect(() => {
    if (!searchQuery) {
      fetchJobList();
    }
    // eslint-disable-next-line
  }, [searchQuery, activeIds]);

  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      type: 'string',
      sortable: true,
      width: 250,
    },
    {
      field: 'employer',
      headerName: 'Employer',
      type: 'string',
      sortable: true,
      width: 250,
      renderHeader: (params: any) => {
        return CreateColumnHeader(params.colDef.headerName);
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      type: 'string',
      sortable: true,
      width: 250,
      renderHeader: (params: any) => {
        return CreateColumnHeader(params.colDef.headerName);
      },
      renderCell: (params: any) => {
        return CreateColumnCell(
          String(params.row.type).replace(/_/g, ' '),
          COLORS.palette.white,
          '',
          2,
          COLORS.palette.lightRed,
          COLORS.palette.red
        );
      },
    },
    {
      field: 'salary',
      headerName: 'Salary',
      type: 'string',
      sortable: true,
      width: 250,
      renderHeader: (params: any) => {
        return CreateColumnHeader(params.colDef.headerName);
      },
      renderCell: (params: any) => {
        return CreateColumnCell(
          `$${params.row.salary}`,
          COLORS.palette.white,
          '',
          2,
          COLORS.palette.lightGreen,
          COLORS.palette.green
        );
      },
    },
    {
      field: 'numApplicants',
      headerName: 'No. of Clients',
      type: 'number',
      sortable: true,
      width: 250,
      renderHeader: (params: any) => {
        return CreateColumnHeader(params.colDef.headerName);
      },
      renderCell: (params: any) => {
        return CreateColumnCell(
          params.row.numApplicants || 0,
          COLORS.palette.white,
          '',
          2,
          COLORS.palette.lightBlue,
          COLORS.palette.blue
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      type: 'string',
      sortable: true,
      width: 250,
      renderCell: (params: any) => dayjs(params.row.createdAt).tz('America/New_York').format('MM/DD/YYYY, hh:mm A'),
    },
    {
      headerName: 'Actions',
      sortable: false,
      width: 200,
      renderHeader: (params: any) => {
        return CreateColumnHeader(params.colDef.headerName);
      },
      renderCell: (params: any) => (
        <Box display={'flex'} gap={1}>
          <Tooltip title='Schedule Interview' arrow>
            <IconButton
              color='primary'
              onClick={(e) => {
                e.stopPropagation();
                setSelectedJob(params.row);
                setShowInterviewScheduleModal(true);
              }}
              disabled={!shouldShowButton('createJob', user?.role)}>
              <PersonAddAltOutlinedIcon
                style={{
                  color: !shouldShowButton('createJob', user?.role)
                    ? COLORS.theme.lightPrimaryColor
                    : COLORS.theme.primaryColor,
                }}
              />
            </IconButton>
          </Tooltip>

          <Tooltip title='Edit' arrow>
            <IconButton
              color='primary'
              onClick={(e) => {
                e.stopPropagation();
                setSelectedJob(params.row);
                setEditMode(true);
                setJobModal(true);
              }}
              disabled={!shouldShowButton('createJob', user?.role)}>
              <EditOutlined
                style={{
                  color: !shouldShowButton('createJob', user?.role)
                    ? COLORS.theme.lightPrimaryColor
                    : COLORS.theme.primaryColor,
                }}
              />
            </IconButton>
          </Tooltip>

          <Tooltip title='Duplicate' arrow>
            <IconButton
              color='success'
              onClick={(e) => {
                e.stopPropagation();
                handleDuplicate(params.row);
              }}
              disabled={!shouldShowButton('createJob', user?.role)}>
              <ContentCopy
                style={{
                  color: !shouldShowButton('createJob', user?.role) ? COLORS.palette.lightGreen : COLORS.palette.green,
                }}
              />
            </IconButton>
          </Tooltip>

          <Tooltip title='Remove' arrow>
            <IconButton
              color='error'
              onClick={(e) => {
                e.stopPropagation();
                setSelectedJob(params.row);
                setShowAlert(true);
              }}
              disabled={!shouldShowButton('createJob', user?.role)}>
              <DeleteOutline
                style={{
                  color: !shouldShowButton('createJob', user?.role) ? COLORS.palette.lightRed : COLORS.palette.red,
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handleSortModelChange = (newSortModel: any) => {
    setSortModel(newSortModel);
  };
  return (
    <Box>
      <CustomTable
        columns={columns}
        hideSearch={false}
        rows={jobList.length ? jobList : []}
        filterList={jobListStatuses}
        loading={loading}
        onFilterListChange={(list: any) => setActiveIds(list)}
        onPaginationModelChange={isSearchMode ? setSearchPaginationModel : setPaginationModel}
        paginationModel={isSearchMode ? searchPaginationModel : paginationModel}
        primaryButtonLable='Create Job'
        primaryButtonAction={() => {
          setEditMode(false);
          setJobModal(true);
        }}
        onRowClick={(params: any) => {
          navigate('../job-info', {
            state: { jobId: params.row.id },
          });
        }}
        disabled={!shouldShowButton('createJob', user?.role)}
        rowCount={jobMetaInfo?.totalRecords || 0}
        title='Job Opportunities'
        onChangeSearchQuery={(query: string) => {
          setSearchQuery(query);
        }}
        handleSearch={() => handleSearch()}
        searchQuery={searchQuery}
        onSortModelChange={handleSortModelChange}
        sortModel={sortModel}
      />

      <AddJob
        open={jobModal}
        data={editMode ? selectedJob : null}
        onClose={() => setJobModal(false)}
        mode={editMode ? 'edit' : 'create'}
        onWriteCurriculum={handleWriteCurriculum}
      />

      <JobInterviewScheduleModal
        open={showInterviewScheduleModal}
        onClose={() => setShowInterviewScheduleModal(false)}
        jobId={selectedJob?.id || ''}
      />

      <GenericDialog
        onClose={() => setShowAlert(false)}
        dialogTitle='Are you sure?'
        agreeBtnText='Delete'
        disagreeBtnText='Cancel'
        agreeFunc={() => handleDelete()}
        open={showAlert}
      />
    </Box>
  );
};

export default Job;
