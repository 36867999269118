import React, { useEffect, useState } from 'react';
import { COLORS } from '../../utils/colors';
import { Box, Button, Chip, CircularProgress, Divider, List, ListItem, ListItemText, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Loader } from '../Loader/Loader';
import { functionButtons, jobOfferStatusEnums, TBD } from './EmploymentEnum';
import { emptyJobInfo, IJobInfo } from '../../store/employmentModule/employmentModal';
import AddRescheduleInterviewModal from './AddRescheduleInterviewModal';
import AddDateTime from '../Modals/AddDateTime/AddDateTime';
import { WriteModeEnum } from '../../utils/writeModeEnum';
import { addNewInterviewTimingInJob, setClientJobOfferStatus } from '../../services/employmentModule.service';
import { Palette } from '../../utils/palette';

export interface IClientScheduledJob {
  jobId: string;
  interviewTimings?: string[];
  oldTimings?: string[];
  scheduledBy: string;
  offerStatus?: string;
}

export interface IScheduledJobDetail extends IJobInfo {
  scheduledJobInterview: IClientScheduledJob;
}

interface IClientScheduledInterviewsProps {
  clientInfo: any;
  clientJobData: (IJobInfo | IScheduledJobDetail)[];
  loading: boolean;
  fetchJobData: Function;
}

const ClientsScheduledInterviews = (props: IClientScheduledInterviewsProps) => {
  const { clientInfo, clientJobData, loading, fetchJobData } = props;
  const [scheduledJobDetail, setScheduledJobDetail] = useState<IScheduledJobDetail[]>([]);
  const [selectedScheduledJob, setSelectedScheduledJob] = useState<IScheduledJobDetail>({
    ...emptyJobInfo,
    scheduledJobInterview: { jobId: '', scheduledBy: '' },
  });
  const [openRescheduleModal, setOpenRescheduleModal] = useState<boolean>(false);
  const [openDateTimeModal, setOpenDateTimeModal] = useState<boolean>(false);
  const [dateTimeLoading, setDateTimeLoading] = useState<boolean>(false);
  const [offerStatusLoading, setOfferStatusLoading] = useState<boolean>(false);
  const [dateTime, setDateTime] = useState<string>('');

  useEffect(() => {
    const jobData = [];
    for (let jobDetail of clientJobData) {
      const scheduledJobInterview = clientInfo?.scheduledJobInterview?.find((i: any) => i.jobId === jobDetail.id);

      if ('scheduledJobInterview' in jobDetail) {
        jobDetail.scheduledJobInterview = scheduledJobInterview;
      } else {
        jobData.push({
          ...jobDetail,
          scheduledJobInterview,
        } as IScheduledJobDetail);
      }
    }
    setScheduledJobDetail(jobData as IScheduledJobDetail[]);
  }, [clientJobData, clientInfo?.scheduledJobInterview]);

  const handleButtonAction = (id: string, job: IScheduledJobDetail) => {
    if (id === '1') {
      setOpenRescheduleModal(!openRescheduleModal);
    } else if (id === '2' || id === '3' || id === '5') {
      const status =
        id === '2'
          ? jobOfferStatusEnums.NO_JOB_OFFER
          : id === '3'
          ? jobOfferStatusEnums.CLIENT_REJECTED_OFFER
          : jobOfferStatusEnums.OFFER_ACCEPTED;
      handleToggleJobOfferStatusWithJob(job, status);
    } else if (id === '4') {
      setOpenDateTimeModal(!openDateTimeModal);
    }
  };

  const handleToggleJobOfferStatusWithJob = async (job: IScheduledJobDetail, status: string) => {
    try {
      setOfferStatusLoading(true);
      await setClientJobOfferStatus(clientInfo?.id, job?.id, status);
      fetchJobData();
      setOfferStatusLoading(false);
    } catch (error) {
      setOfferStatusLoading(false);
      // Handle error
    }
  };

  const handleClose = () => {
    setOpenDateTimeModal(!openDateTimeModal);
    setDateTime('');
  };

  const handleSubmit = async () => {
    try {
      setDateTimeLoading(true);
      await addNewInterviewTimingInJob(selectedScheduledJob?.id, dateTime);
      handleClose();
      fetchJobData();
      setDateTimeLoading(false);
    } catch (error) {
      setDateTimeLoading(false);
      //catch err
    }
  };

  const isOfferAccepted = clientInfo?.scheduledJobInterview?.some(
    (item: IClientScheduledJob) => item.offerStatus === jobOfferStatusEnums.OFFER_ACCEPTED
  );

  return (
    <>
      <Box sx={{ background: COLORS.card.cardBg }} borderRadius={'10px'} p={2} display={'block'}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant='h6'>Scheduled Interviews</Typography>
        </Box>
        <Box py={1}>
          <Divider variant='fullWidth' light={true} />
        </Box>
        <Box sx={{ p: 1, my: 1 }}>
          {loading ? (
            <Loader loaderBoxStyle={{ height: '30vh' }} />
          ) : (
            scheduledJobDetail.length > 0 &&
            scheduledJobDetail.map((job) => (
              <Box key={job.id} sx={{ border: '1px solid grey', p: 1, mb: 2 }}>
                <List key={job.id}>
                  <ListItem disablePadding key={'Title'}>
                    <ListItemText
                      primary={'Title:'}
                      secondary={job.title}
                      primaryTypographyProps={styles.listItemPrimaryText}
                      sx={styles.listItemContainer}
                    />
                  </ListItem>
                  <ListItem disablePadding key={'Employer'}>
                    <ListItemText
                      primary={'Employer:'}
                      secondary={job.employer}
                      primaryTypographyProps={styles.listItemPrimaryText}
                      sx={styles.listItemContainer}
                    />
                  </ListItem>
                  <ListItem disablePadding key={'Job Type'}>
                    <ListItemText
                      primary={'Job Type:'}
                      secondary={job.type}
                      primaryTypographyProps={styles.listItemPrimaryText}
                      sx={styles.listItemContainer}
                    />
                  </ListItem>
                  <ListItem disablePadding key={'Scheduled Interview Date'}>
                    <ListItemText
                      primary={'Scheduled Interview Date:'}
                      secondary={job.scheduledJobInterview?.interviewTimings
                        ?.map((i: string) =>
                          i !== TBD ? dayjs(i).tz('America/New_York').format('MM/DD/YYYY, hh:mm A') : i
                        )
                        .join(' , ')}
                      primaryTypographyProps={{ ...styles.listItemPrimaryText }}
                      sx={styles.listItemContainer}
                    />
                  </ListItem>
                  <ListItem disablePadding key={'Interview Count'}>
                    <ListItemText
                      primary={'Interview Count:'}
                      secondary={clientInfo?.scheduledJobInterview?.length}
                      primaryTypographyProps={styles.listItemPrimaryText}
                      sx={styles.listItemContainer}
                    />
                  </ListItem>
                </List>
                <Typography color={'error'}>
                  {job.scheduledJobInterview?.oldTimings
                    ?.map((i: string) =>
                      i !== TBD ? dayjs(i).tz('America/New_York').format('MM/DD/YYYY, hh:mm A') : ''
                    )
                    .join(' , ')}
                </Typography>
                {offerStatusLoading ? (
                  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress size={20} sx={{ my: 1 }} />
                  </Box>
                ) : !isOfferAccepted &&
                  job?.scheduledJobInterview?.offerStatus !== jobOfferStatusEnums.CLIENT_REJECTED_OFFER &&
                  job?.scheduledJobInterview?.offerStatus !== jobOfferStatusEnums.NO_JOB_OFFER ? (
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
                    {functionButtons.map((item: { id: string; title: string }) => (
                      <Button
                        key={item.id}
                        sx={{
                          textDecoration: 'underline',
                          fontSize: '0.75rem',
                          textTransform: 'capitalize',
                        }}
                        onClick={() => {
                          setSelectedScheduledJob(job);
                          handleButtonAction(item.id, job);
                        }}>
                        {item.title}
                      </Button>
                    ))}
                  </Box>
                ) : null}
                {job.scheduledJobInterview?.offerStatus ? (
                  <Chip
                    size='medium'
                    sx={{
                      mt: 1,
                      background:
                        job.scheduledJobInterview?.offerStatus === jobOfferStatusEnums.OFFER_ACCEPTED
                          ? Palette.green_E4F3E5
                          : job.scheduledJobInterview?.offerStatus === jobOfferStatusEnums.CLIENT_REJECTED_OFFER
                          ? Palette.grey_5657581F
                          : Palette.red_FFCDD2,
                      color:
                        job.scheduledJobInterview?.offerStatus === jobOfferStatusEnums.OFFER_ACCEPTED
                          ? Palette.green_4CAF50
                          : job.scheduledJobInterview?.offerStatus === jobOfferStatusEnums.CLIENT_REJECTED_OFFER
                          ? Palette.black
                          : Palette.red_D50000,
                    }}
                    label={
                      <Typography
                        variant='button'
                        sx={{
                          fontSize: '12px',
                          textTransform: 'Capitalize',
                          lineHeight: 1.5,
                        }}>
                        {job.scheduledJobInterview?.offerStatus === jobOfferStatusEnums.CLIENT_REJECTED_OFFER
                          ? 'Client Rejected Offer'
                          : job.scheduledJobInterview?.offerStatus === jobOfferStatusEnums.NO_JOB_OFFER
                          ? 'No Job Offer'
                          : job.scheduledJobInterview?.offerStatus === jobOfferStatusEnums.OFFER_ACCEPTED
                          ? 'Offer Accepted'
                          : ''}
                      </Typography>
                    }
                  />
                ) : null}
              </Box>
            ))
          )}
        </Box>
      </Box>
      <AddRescheduleInterviewModal
        open={openRescheduleModal}
        onClose={() => setOpenRescheduleModal(!openRescheduleModal)}
        jobDetail={selectedScheduledJob}
        clientInfo={clientInfo}
      />
      <AddDateTime
        open={openDateTimeModal}
        onClose={handleClose}
        mode={WriteModeEnum.CREATE}
        handleSubmit={handleSubmit}
        setDateTime={setDateTime}
        dateTime={dateTime}
        loading={dateTimeLoading}
        isAddInterviewDate={true}
      />
    </>
  );
};

export default ClientsScheduledInterviews;

const styles = {
  listItemContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  listItemPrimaryText: {
    fontWeight: 'bold',
    paddingRight: 2,
    alignSelf: 'flex-start',
  },
  jobTitle: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  jobDescription: {
    fontSize: 16,
  },
};
