import {
  AccessTimeTwoTone,
  CheckBoxTwoTone,
  LocalActivityTwoTone,
  PendingTwoTone,
  PermIdentityTwoTone,
  Archive,
  PlaylistAddCheck,
} from '@mui/icons-material';
import { COLORS } from '../../../utils/colors';
import { ClientStatus } from '../../../utils/clientStatus';

//eslint-disable-next-line
const totalHousehold =
  //eslint-disable-next-line
  'Total household income less than: $45k for 1 person; $61k for 2 people; $77k for 3 people; $93k for 4 people; $109k for 5 people; $125k for 6 people.';

export const ClientStepper = (isSelf: boolean, customUrlName: string | undefined) => {
  const steps = [
    { id: 1, title: 'Basic Info' },
    { id: 2, title: 'Other Info' },
    {
      id: 3,
      title:
        isSelf && !customUrlName
          ? 'Info-session List'
          : isSelf && customUrlName
          ? 'Info-session Detail'
          : 'Upload Docs',
    },
  ];
  if (isSelf) {
    steps.push({ id: 4, title: 'Confirmation' });
  }

  return steps;
};

export const genders = [
  { id: 1, title: 'Male' },
  { id: 2, title: 'Female' },
  { id: 3, title: 'Other' },
];

export const ethnics = [
  { id: 1, title: 'Native American or Alaskan Native' },
  { id: 2, title: 'African American / African / Afro-Caribbean' },
  { id: 3, title: 'Asian / Pacific Islander or Native Hawaiian' },
  { id: 4, title: 'Latino or Hispanic' },
  { id: 5, title: 'White (not Latino/a)' },
  { id: 6, title: 'Prefer not to disclose' },
  { id: 7, title: 'Multiracial' },
  { id: 8, title: 'Other, Specify' },
];

export const employeeStatus = [
  { id: 1, title: 'Employee Full - Time' },
  { id: 2, title: 'Employee Part - Time' },
  { id: 4, title: 'Unemployed' },
];

export const educations = [
  { id: 1, title: 'Less than 12th Grade' },
  { id: 2, title: 'High School Diploma or Equivalent' },
  { id: 3, title: 'Post Secondary Education' },
  { id: 4, title: 'Some College' },
  { id: 5, title: 'Associate’s Degree or higher' },
];

export const publicAssistance = [
  { id: 1, title: 'Cash Assistance Receipt' },
  { id: 2, title: 'SNAP Receipt' },
  { id: 3, title: 'Medicaid Receipt' },
  { id: 4, title: 'NYCHA Resident' },
  { id: 5, title: 'Non-Custodial Parent' },
  { id: 6, title: totalHousehold },
  { id: 7, title: 'Prefer not to disclose' },
  { id: 8, title: 'None of these options apply to me' },
];

export const interestedInOptions = [
  { id: 1, title: 'Construction Training' },
  { id: 2, title: 'Industrial/Transportation Training' },
  { id: 3, title: 'Union/Apprenticeship Opportunities' },
  { id: 4, title: 'Employment Opportunities' },
  { id: 5, title: 'Supportive Services' },
];

export const referralSource = [
  { id: 1, title: 'Administration for Children Services (ACS)' },
  { id: 2, title: 'Career Service' },
  { id: 3, title: 'Jobs-Plus' },
  { id: 4, title: 'NYCHA' },
  { id: 5, title: 'Office of Child Support Services (OCSS)' },
  { id: 7, title: 'Business Link' },
  { id: 8, title: 'NY Public Library Bx, NY, Queens' },
  { id: 6, title: 'Other, Specify' },
];

export const ReferralSourceConst = {
  COMMUNITY_BASED_ORG: 'Other, Specify',
  COMMUNITY_BASED_ORG_ID: 6,
};

export const highestDL = [
  { id: 1, title: 'No license' },
  { id: 2, title: 'Class D Learner’s permit (regular driver’s license permit)' },
  { id: 3, title: 'Class D license (regular driver’s license)' },
  { id: 4, title: 'Class C permit' },
  { id: 5, title: 'Class C license' },
  { id: 6, title: 'Class B permit' },
  { id: 7, title: 'Class B license' },
  { id: 8, title: 'Class A permit' },
  { id: 9, title: 'Class A license' },
];

export const emptyBasicDetails: IBasicDetails = {
  firstName: '',
  middleName: '',
  lastName: '',
  address1: '',
  address2: '',
  mobile: '',
  state: '',
  homeNumber: '',
  city: '',
  zip: '',
  workNumber: '',
  dob: '',
  ssn: '',
  email: '',
};

export const emptyOtherDetails: IOtherDetails = {
  gender: '',
  otherGender: '',
  ethnic: '',
  employmentStatus: '',
  educationalBackground: '',
  caseNumber: '',
  publicAssistance: '',
  publicAssistanceOther: '',
  ethnicOther: '',
  referralSource: '',
  refCommunityOrg: '',
  interestedIn: [],
  highestDL: '',
};

export const emptyUploadDocs: IUploadDocs = {
  urls: [],
  infoSessionIds: [],
  notes: [],
};

export interface IBasicDetails {
  firstName: string;
  middleName: string;
  lastName: string;
  address1: string;
  address2: string;
  mobile: string;
  state: string;
  homeNumber: string;
  city: string;
  zip: string;
  workNumber: string;
  dob: string;
  ssn: string;
  email: string;
}

export interface IOtherDetails {
  gender: string;
  otherGender: string;
  ethnic: string;
  employmentStatus: string;
  educationalBackground: string;
  caseNumber: string;
  publicAssistance: string;
  publicAssistanceOther: string;
  ethnicOther: string;
  referralSource: string;
  refCommunityOrg: string;
  interestedIn: any[];
  highestDL: string;
}

export interface IUploadDocs {
  urls: Array<string>;
  infoSessionIds: Array<string>;
  notes: Array<string>;
}

export const ClientListStatuses = [
  {
    id: 'all',
    label: 'all',
    bgColor: COLORS.client.allClientBg,
    textColor: COLORS.client.allClientColor,
    icon: PermIdentityTwoTone,
  },
  {
    id: 'prospective',
    label: 'prospective',
    tooltip:
      // eslint-disable-next-line max-len
      'Clients in this status have shown interest in PINCC by creating a client profile. Prospective clients have either signed up for an information session to receive information about PINCC training opportunities and wrap-around services or are direct-to-work/Union clients. All prospective clients maintain this status until they attend an information session and/or engage with a PINCC team member, at which point they are formally converted into clients.',
    bgColor: COLORS.client.prospectiveClientBg,
    textColor: COLORS.client.prospectiveClientColor,
    icon: PermIdentityTwoTone,
  },
  {
    id: 'pending_assignment',
    label: 'pending_navigator',
    bgColor: COLORS.client.pendingAssignmentBg,
    textColor: COLORS.client.pendingAssignmentColor,
    icon: PendingTwoTone,
    tooltip:
      // eslint-disable-next-line max-len
      'Clients in this status have received information regarding PINCC and/or PINCC job opportunities and are awaiting assignment to a navigator by the PINCC Deputy Director, the Data Analyst, or a designated HRA staff member for further assistance with program intake. Direct-to-work clients are assigned to a staff member who can immediately assign the client to a direct to work training track for further servicing.',
  },
  {
    id: 'pending_enrollment',
    label: 'pending_enrollment',
    bgColor: COLORS.client.pendingEnrollmentBg,
    textColor: COLORS.client.pendingEnrollmentColor,
    icon: AccessTimeTwoTone,
    tooltip:
      // eslint-disable-next-line max-len
      "Clients in this status have been assigned to a navigator by the PINCC Deputy Director, the Data Analyst, or a designated HRA staff member. They are currently undergoing the intake process and awaiting referral to a training program. Navigators are in the process of verifying identification, collecting signed documentation, and assessing interest and eligibility for training programs. The client's eligibility for certain trainings is being reviewed by HRA. Direct-to-work clients are immediately forwarded to “enrolled” status.",
  },
  {
    id: 'enrollment_review',
    label: 'enrollment_review',
    bgColor: COLORS.client.enrollmentReviewBg,
    textColor: COLORS.client.enrollmentReviewColor,
    icon: AccessTimeTwoTone,
    tooltip:
      // eslint-disable-next-line max-len
      'Clients in this status have been referred to a training program and are awaiting acceptance. They are currently undergoing additional screening by the training provider. If clients are not accepted into a training program, they will stay under Enrollment Review for said training program.',
  },
  {
    id: 'enrolled',
    label: 'enrolled',
    bgColor: COLORS.client.enrolledBg,
    textColor: COLORS.client.enrolledColor,
    icon: CheckBoxTwoTone,
    tooltip:
      // eslint-disable-next-line max-len
      'Clients in this status have been approved by the training provider and HRA (if applicable) to begin a training program. Direct-to-work clients remain in this status under a direct to work training track and sent to the employment team. Clients who have been approved, but do not show up to their first day of training are marked as “Day 1 No Show” dropouts after being assigned “enrolled” status.',
  },
  {
    id: 'completed',
    label: 'completed',
    bgColor: COLORS.client.completedBg,
    textColor: COLORS.client.completedColor,
    icon: LocalActivityTwoTone,
    tooltip:
      // eslint-disable-next-line max-len
      'Clients in this status have completed their training program and are pending employment. All clients who have completed their training program are sent to the employment team, if not already done earlier.',
  },
  {
    id: 'drop_out',
    label: 'drop_out',
    bgColor: COLORS.client.dropoutBg,
    textColor: COLORS.client.dropoutColor,
    icon: LocalActivityTwoTone,
    tooltip:
      // eslint-disable-next-line max-len
      'Clients in this status have been removed from enrollment due to an EDA-defined cause or did not attend their first day of training. Clients who drop out of a training program may sign up for another training opportunity down the line.',
  },
  {
    id: 'suspended',
    label: 'suspended',
    bgColor: COLORS.client.suspendedClientBg,
    textColor: COLORS.client.suspendedClientColor,
    icon: LocalActivityTwoTone,
    tooltip:
      // eslint-disable-next-line max-len
      'Clients in this status have been permanently removed from PINCC due to inappropriate behavior, refusal to comply with intake, or a request to not be contacted. Rejoining PINCC requires the approval from the PINCC Executive Director or the CWE PINCC Deputy Director.',
  },
];

export const ProgramTrackListStatuses = [
  {
    id: 1,
    label: 'archive',
    bgColor: COLORS.client.prospectiveClientBg,
    textColor: COLORS.client.prospectiveClientColor,
    icon: Archive,
  },
  {
    id: 2,
    label: 'active',
    bgColor: COLORS.client.pendingAssignmentBg,
    textColor: COLORS.client.pendingAssignmentColor,
    icon: PlaylistAddCheck,
  },
  {
    id: 3,
    label: 'future',
    bgColor: COLORS.client.pendingEnrollmentBg,
    textColor: COLORS.client.pendingEnrollmentColor,
    icon: PendingTwoTone,
  },
];

export const InfoSessionListStatuses = [
  {
    id: 1,
    label: 'active',
    bgColor: COLORS.client.prospectiveClientBg,
    textColor: COLORS.client.prospectiveClientColor,
    icon: PlaylistAddCheck,
  },
  {
    id: 2,
    label: 'finished',
    bgColor: COLORS.client.pendingAssignmentBg,
    textColor: COLORS.client.pendingAssignmentColor,
    icon: Archive,
  },
];

export const StaffFilterList = [
  {
    id: 'all',
    label: 'all',
    bgColor: COLORS.client.dropoutBg,
    textColor: COLORS.client.dropoutColor,
    icon: LocalActivityTwoTone,
  },
  {
    id: 'admin',
    label: 'admin',
    bgColor: COLORS.client.prospectiveClientBg,
    textColor: COLORS.client.prospectiveClientColor,
    icon: PermIdentityTwoTone,
  },
  {
    id: 'navigator',
    label: 'navigator',
    bgColor: COLORS.client.pendingAssignmentBg,
    textColor: COLORS.client.pendingAssignmentColor,
    icon: PendingTwoTone,
  },
  {
    id: 'trainer',
    label: 'trainer',
    bgColor: COLORS.client.pendingEnrollmentBg,
    textColor: COLORS.client.pendingEnrollmentColor,
    icon: AccessTimeTwoTone,
  },
  {
    id: 'caseManager',
    label: 'Case Manager',
    bgColor: COLORS.client.enrolledBg,
    textColor: COLORS.client.enrolledColor,
    icon: CheckBoxTwoTone,
  },
  {
    id: 'superAdmin',
    label: 'superAdmin',
    bgColor: COLORS.client.completedBg,
    textColor: COLORS.client.completedColor,
    icon: LocalActivityTwoTone,
  },
  {
    id: 'employer',
    label: 'employer',
    bgColor: COLORS.client.suspendedClientBg,
    textColor: COLORS.client.suspendedClientColor,
    icon: LocalActivityTwoTone,
  },
  {
    id: 'employmentLiaison',
    label: 'employment Liaison',
    bgColor: COLORS.client.prospectiveClientBg,
    textColor: COLORS.client.prospectiveClientColor,
    icon: PermIdentityTwoTone,
  },
];

export const categoryDesignList = [
  {
    id: 'generalNote',
    label: 'General Note',
    bgColor: COLORS.notesCategory.generalNoteBg,
    textColor: COLORS.notesCategory.generalNoteColor,
  },
  {
    id: 'declinedServices',
    label: 'Declined services',
    bgColor: COLORS.notesCategory.generalNoteBg,
    textColor: COLORS.notesCategory.generalNoteColor,
  },
  {
    id: 'awaitingDoc',
    label: 'Awaiting documentation',
    bgColor: COLORS.notesCategory.awaitingDocBg,
    textColor: COLORS.notesCategory.awaitingDocColor,
  },
  {
    id: 'followUp',
    label: 'Follow up',
    bgColor: COLORS.notesCategory.folloUpBg,
    textColor: COLORS.notesCategory.folloUpColor,
  },
  {
    id: 'attemptedFollowUp',
    label: 'Attempted follow-up',
    bgColor: COLORS.notesCategory.attemptedFollowUpBg,
    textColor: COLORS.notesCategory.attemptedFollowUpColor,
  },
  {
    id: 'programTrack',
    label: 'Program Track',
    bgColor: COLORS.notesCategory.programTrackBg,
    textColor: COLORS.notesCategory.programTrackColor,
  },
  {
    id: 'progressNote',
    label: 'Progress Note',
    bgColor: COLORS.notesCategory.progressNoteBg,
    textColor: COLORS.notesCategory.progressNoteColor,
  },
];
export const getStatusColors = (status?: string) => {
  const colors = ClientListStatuses.find((i) => i.label == status);
  return colors;
};

export const getEmploymentStatusColors = (status?: string) => {
  const colors = ClientEmploymentList.find((i) => i.label == status);
  return colors;
};

export const getNotesCategoryColors = (category?: string) => {
  const colors = categoryDesignList.find((i) => i.label == category);
  return colors;
};

export const jobListStatuses = [
  {
    id: 1,
    label: 'active',
    bgColor: COLORS.client.prospectiveClientBg,
    textColor: COLORS.client.prospectiveClientColor,
    icon: PlaylistAddCheck,
  },
  {
    id: 2,
    label: 'archive',
    bgColor: COLORS.client.pendingAssignmentBg,
    textColor: COLORS.client.pendingAssignmentColor,
    icon: Archive,
  },
];

export const ClientEmploymentList = [
  {
    id: 1,
    label: 'all',
    bgColor: COLORS.client.dropoutBg,
    textColor: COLORS.client.dropoutColor,
    icon: LocalActivityTwoTone,
  },
  {
    id: 2,
    label: 'Pending Employment Liaison',
    bgColor: COLORS.client.prospectiveClientBg,
    textColor: COLORS.client.prospectiveClientColor,
    icon: PermIdentityTwoTone,
  },
  {
    id: 3,
    label: 'Pending Employment Intake',
    bgColor: COLORS.client.pendingAssignmentBg,
    textColor: COLORS.client.pendingAssignmentColor,
    icon: PendingTwoTone,
  },
  {
    id: 4,
    label: 'Enrolled in Workshop',
    bgColor: COLORS.client.pendingEnrollmentBg,
    textColor: COLORS.client.pendingEnrollmentColor,
    icon: AccessTimeTwoTone,
  },
  {
    id: 5,
    label: 'Job Ready',
    bgColor: COLORS.client.enrolledBg,
    textColor: COLORS.client.enrolledColor,
    icon: CheckBoxTwoTone,
  },
  {
    id: 6,
    label: 'Interview scheduled',
    bgColor: COLORS.client.completedBg,
    textColor: COLORS.client.completedColor,
    icon: LocalActivityTwoTone,
  },
  {
    id: 7,
    label: 'Hired',
    bgColor: COLORS.client.suspendedClientBg,
    textColor: COLORS.client.suspendedClientColor,
    icon: LocalActivityTwoTone,
  },
];

export const renderClientsEmploymentStatus = (data: any) => {
  if (!data.employmentLiaisonId) {
    return 'Pending Employment Liaison';
  } else if (data.employmentLiaisonId && data.employment) {
    return 'Hired';
  } else if (data.employmentLiaisonId && data.scheduledJobInterview) {
    return 'Interview scheduled';
  } else if (data.employmentLiaisonId && data.workshopId && data.completedWorkshops) {
    return 'Job Ready';
  } else if (data.employmentLiaisonId && data.workshopId) {
    return 'Enrolled in Workshop';
  } else if (data.employmentLiaisonId && !data.employment) {
    return 'Pending Employment Intake';
  } else if (data.status === ClientStatus.PROSPECTIVE && !data.employment) {
    return 'Prospective';
  } else {
    return '--';
  }
};
