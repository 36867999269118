import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Grid, Box, Typography, Button, IconButton, Divider, TextField, Autocomplete, Chip } from '@mui/material';
import { AddCircleOutline, Clear } from '@mui/icons-material';
import { useFormik } from 'formik';
import { Check, ChevronLeft } from '@mui/icons-material';
import { LoadingButton } from '../../../../components/LoadingButton/LoadingButton';
import { COLORS } from '../../../../utils/colors';
import { uploadFiles } from '../../../../services/client.service';
import { categories } from '../../../../utils/categoryOptions';
import { fetchInfoSessionsByCustomUrlName, fetchInfoSessionList } from '../../../../services/infoSession.service';
import InfoSessionBasicInfo from '../../../../components/InfoSessionBasicInfo/InfoSessionBasicInfo';
import { InfoSessionEditModel, infoSessionEmpty } from '../../../../store/infoSession/infoSessionModel';
import { infoSessionTypes } from '../../../../utils/infoSessionTypeEnums';
import UserAgreement from '../../../../components/UserAgreement/UserAgreement';

const schema = yup.object().shape({
  files: yup.mixed().test('fileSize', 'File Size is too large', (value: any) => {
    if (value && value?.length > 0) {
      for (let i = 0; i < value.length; i++) {
        if (value[i].size > 25 * 1024 * 1024) {
          return false;
        }
      }
    }
    return true;
  }),
  notes: yup.string(),
});
const UploadDocs = (props: any) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isUserAgree, setIsUserAgree] = useState<boolean>(false);
  const [isAgreeToTextMessaging, setIsAgreeToTextMessaging] = useState<boolean>(false);
  const [inPersonSessionsList, setInPersonSessionList] = useState<InfoSessionEditModel[]>([]);
  const [virtualSessionsList, setVirtualSessionList] = useState<InfoSessionEditModel[]>([]);
  const [filesInfo, setFilesInfo] = React.useState<Array<any>>([]);
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const [selectedInfoSessionIds, setSelectedInfoSessionIds] = useState<string[]>([]);
  const [infoSessionData, setInfoSessionData] = useState<InfoSessionEditModel[]>([infoSessionEmpty]);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [showAgreementWarning, setShowAgreementWarning] = useState<boolean>(false);
  const handleInfoSessionSelectChange = (infoSessionId: string, isSelected: boolean) => {
    if (isSelected) {
      setSelectedInfoSessionIds((prevIds) => {
        const newIds = [...prevIds, infoSessionId];
        setShowWarning(newIds.length > 2);
        return newIds;
      });
    } else {
      setSelectedInfoSessionIds((prevIds) => {
        const newIds = prevIds.filter((id) => id !== infoSessionId);
        setShowWarning(newIds.length > 2);
        return newIds;
      });
    }
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const getInfoSessionList = async () => {
    try {
      const unsubscribe = await fetchInfoSessionList((sessionsList: InfoSessionEditModel[]) => {
        if (sessionsList && sessionsList.length) {
          const sessionDateTimeList = sessionsList.map((item: InfoSessionEditModel) => ({
            ...item,
            dateTime: new Date(item.dateTime).toISOString(),
          }));

          const inPersonSessions = sessionDateTimeList.filter(
            (session) => session.sessionType === infoSessionTypes.IN_PERSON
          );
          const virtualSessions = sessionDateTimeList.filter(
            (session) => session.sessionType === infoSessionTypes.VIRTUAL
          );

          const today = new Date();
          const fourteenDaysFromNow = new Date();
          fourteenDaysFromNow.setDate(today.getDate() + 14);

          const filterAndSortSessions = (sessions: any) => {
            return sessions
              .filter((session: any) => {
                const sessionDate = new Date(session.dateTime).getTime();
                const startOfToday = today.setHours(0, 0, 0, 0);
                const endOfFourteenDays = fourteenDaysFromNow.setHours(23, 59, 59, 999);

                return sessionDate >= startOfToday && sessionDate <= endOfFourteenDays;
              })
              .sort((a: any, b: any) => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime());
          };
          const inPersonSorted = filterAndSortSessions(inPersonSessions);
          const virtualSorted = filterAndSortSessions(virtualSessions);

          setInPersonSessionList(inPersonSorted.slice(0, 5));
          setVirtualSessionList(virtualSorted.slice(0, 5));
        }
      });
      return () => {
        unsubscribe();
      };
    } catch (err) {
      // Handle error here
    }
  };

  const getSessionDetailByCustomUrlName = async () => {
    try {
      setLoading(true);
      const result: any = await fetchInfoSessionsByCustomUrlName(props.customUrlName || '');
      setInfoSessionData(result);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      //catch err
    }
  };

  useEffect(
    () => {
      if (props.customUrlName) {
        getSessionDetailByCustomUrlName();
      } else {
        getInfoSessionList();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (props.isBackMode) {
      setSelectedInfoSessionIds(props.data.infoSessionIds);
    }
    // eslint - disable - next - line;
  }, [props?.isBackMode, props?.data]);

  const removeFile = (name: string) => {
    if (filesInfo.length) {
      const filteredFiles = filesInfo.filter((file: any) => file.name !== name);
      const finalFilesList = filteredFiles?.length ? [...filteredFiles] : [];

      setFilesInfo(finalFilesList);
      formik.setFieldValue('files', finalFilesList?.length ? finalFilesList : '');
    }
  };

  const handleFileChange = (event: any) => {
    const { files } = event.target;

    if (files?.length) {
      setFilesInfo((prevState: any[]) => [...prevState, ...files]);
      formik.setFieldValue('files', formik.values.files.concat(files));
    }
  };

  const formik = useFormik({
    initialValues: {
      files: '',
      category: '',
      title: '',
      description: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      if (props.isSelf) {
        if (isUserAgree) {
          setLoading(true);
          const urls = await uploadFiles(filesInfo);

          let dataInfo = {
            files: urls,
            category: values.category,
            title: values.title,
            description: values.description,
            infoSessionIds: selectedInfoSessionIds,
            isUserAgreeToTerms: isUserAgree,
            isAgreeToTextMessaging: isAgreeToTextMessaging,
          };

          await props.handleNext(dataInfo);
        } else {
          setShowAgreementWarning(true);
        }
      } else {
        setLoading(true);
        const urls = await uploadFiles(filesInfo);

        let dataInfo = {
          files: urls,
          category: values.category,
          title: values.title,
          description: values.description,
          infoSessionIds: selectedInfoSessionIds,
          isUserAgreeToTerms: isUserAgree,
        };

        await props.handleNext(dataInfo);
      }
    },
  });

  const shouldShowUserAgreement = props.customUrlName
    ? infoSessionData.length > 0
    : virtualSessionsList.length > 0 || inPersonSessionsList.length > 0;

  return (
    <Box sx={{ width: '100%', mt: 5 }}>
      <form onSubmit={formik.handleSubmit}>
        {!props.isSelf && (
          <Grid container alignItems={'center'}>
            <Grid item xs={12} lg={4}></Grid>
            <Grid item xs={10} lg={4}>
              <Typography variant='h6'>Upload Documents</Typography>
            </Grid>
            <Grid item xs={2} lg={2}>
              <IconButton size='large' onClick={handleClick}>
                <AddCircleOutline />
              </IconButton>
            </Grid>
            <Grid item xs={12} lg={12}>
              {formik.errors.files ? (
                <p style={{ color: COLORS.result.error, fontSize: '12px', textAlign: 'center' }}>
                  {formik.errors.files}
                </p>
              ) : null}
            </Grid>
          </Grid>
        )}

        <Grid container direction={'column'} alignItems={'center'}>
          {filesInfo?.length
            ? filesInfo.map((fileDetails: any, index: number) => (
                <Grid key={index.toString()} item xs={12} lg={5} marginBottom={2}>
                  <Box
                    sx={{
                      width: '100%',
                      height: 40,
                      backgroundColor: COLORS.palette.lightGreen,
                      border: '1px dashed grey',
                    }}>
                    <Grid container alignItems={'center'} sx={{ px: 2 }}>
                      <Grid item xs={11} lg={11}>
                        <Typography variant='caption' align='center'>
                          {fileDetails?.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={1} lg={1}>
                        <IconButton size='small' onClick={() => removeFile(fileDetails?.name)}>
                          <Clear />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ))
            : null}
        </Grid>

        {!props.isSelf && (
          <Grid container justifyContent={'center'} sx={{ my: 2 }}>
            <Grid item xs={12} lg={5}>
              <Divider variant='fullWidth' light={true} />
              <input
                name='files'
                type='file'
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
                multiple
              />
            </Grid>
          </Grid>
        )}

        <Grid container ml={4} justifyContent='center'>
          {!props.isSelf ? (
            <Grid item xs={12} lg={4}>
              <Grid item mb={2}>
                <Typography variant='h6'>Client Notes</Typography>
              </Grid>
              <Grid item style={{ marginBottom: '10px' }}>
                <Autocomplete
                  fullWidth
                  disableClearable
                  value={{ id: 1, label: formik.values.category }}
                  options={categories.map((item: any) => ({
                    label: item.value,
                    id: item.id,
                  }))}
                  getOptionLabel={(option: any) => option.label}
                  onChange={(event: any, object: any) => {
                    formik.setFieldValue('category', object.label);
                  }}
                  renderInput={(params: any) => <TextField {...params} label='select category' />}
                  isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                />
              </Grid>
              <TextField
                fullWidth
                placeholder='Notes title'
                name='title'
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
              <Grid item style={{ marginTop: '10px' }}>
                <TextField
                  multiline
                  fullWidth
                  rows={5}
                  placeholder='Notes description goes here'
                  name='description'
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                />
              </Grid>
            </Grid>
          ) : (
            !props.customUrlName && (
              <Box mr={4}>
                <Typography variant='h6'>Select an upcoming date that works for you.</Typography>
                <Box py={2}>
                  <Divider>
                    <Chip label='Virtual' sx={{ fontWeight: 'bold' }} />
                  </Divider>
                </Box>
                <Grid container spacing={2}>
                  {virtualSessionsList.map((item: InfoSessionEditModel) => (
                    <Grid key={item.id} item xs={12} sm={6} md={4} lg={4}>
                      <Grid p={2} sx={{ background: COLORS.card.cardBg }} borderRadius={'10px'}>
                        <InfoSessionBasicInfo
                          infoSessionData={item}
                          onSelectChange={handleInfoSessionSelectChange}
                          selectedInfoSessionIds={selectedInfoSessionIds}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <Box py={2}>
                  <Divider>
                    <Chip label='In-Person' sx={{ fontWeight: 'bold' }} />
                  </Divider>
                </Box>
                <Grid container spacing={2}>
                  {inPersonSessionsList.map((item: InfoSessionEditModel) => (
                    <Grid key={item.id} item xs={12} sm={6} md={4} lg={4}>
                      <Grid p={2} sx={{ background: COLORS.card.cardBg }} borderRadius={'10px'}>
                        <InfoSessionBasicInfo
                          infoSessionData={item}
                          onSelectChange={handleInfoSessionSelectChange}
                          selectedInfoSessionIds={selectedInfoSessionIds}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )
          )}
        </Grid>

        {props.customUrlName && (
          <Grid container spacing={2} ml={2} my={2}>
            <Typography mb={1} variant='h6'>
              Info Session Details
            </Typography>
            <Grid container spacing={2}>
              {infoSessionData.map((item: InfoSessionEditModel) => (
                <Grid key={item?.id} item xs={12} sm={6} md={4} lg={4}>
                  <Grid p={2} sx={{ background: COLORS.card.cardBg }} borderRadius={'10px'}>
                    <InfoSessionBasicInfo
                      infoSessionData={item}
                      onSelectChange={handleInfoSessionSelectChange}
                      selectedInfoSessionIds={selectedInfoSessionIds}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}

        {props.isSelf &&
          (shouldShowUserAgreement ? (
            <Box mt={2} ml={2}>
              <UserAgreement
                fullName={props.fullName}
                setShowAgreementWarning={setShowAgreementWarning}
                setIsUserAgree={setIsUserAgree}
                isUserAgree={isUserAgree}
                isAgreeToTextMessaging={isAgreeToTextMessaging}
                setIsAgreeToTextMessaging={setIsAgreeToTextMessaging}
              />
            </Box>
          ) : null)}

        {showWarning && (
          <Typography variant='body2' color='error' sx={{ m: 2 }}>
            Warning: Please select only up to 2 info sessions.
          </Typography>
        )}
        {showAgreementWarning && (
          <Typography variant='body2' color='error' sx={{ m: 2 }}>
            Warning: Please ensure that the user agreement checked..
          </Typography>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 5 }}>
          <Button
            color='inherit'
            disabled={false}
            onClick={props.handleBack}
            sx={{ mr: 1 }}
            startIcon={<ChevronLeft />}
            size={'large'}>
            Back
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <LoadingButton
            type='submit'
            variant='contained'
            disabled={
              props.isSelf
                ? selectedInfoSessionIds?.length < 1 || selectedInfoSessionIds?.length > 2 || !isUserAgree
                : false
            }
            // @ts-ignore
            color='darkModePrimary'
            loading={props.isSelf ? props.loading : loading}
            size='large'
            endIcon={<Check />}
            label={'Finish'}
          />
        </Box>
      </form>
    </Box>
  );
};

export default UploadDocs;
