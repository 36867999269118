import React, { useState } from 'react';
import { Box, Grid, Typography, IconButton, Tooltip } from '@mui/material';
import { ProgramTrackListStatuses } from '../client/utils';
import { COLORS } from '../../../utils/colors';
import { toast } from 'react-toastify';
import {
  IProgramTrack,
  getProgramTracksByQuery,
  IProgramTrackMetaInfo,
  searchProgramTracks,
  pseudoAddProgramTrack,
} from '../../../services/programTrack.service';
import { convertTimeStampToDate } from '../../../utils/dateTime';
import { CreateColumnCell } from '../../../components/CreateColumnCell/CreateColumnCell';
import { CreateColumnHeader } from '../../../components/CreateColumnHeader/CreateColumnHeader';
import moment from 'moment-timezone';
import GenericDialog from '../../../components/Dialog/genericDialog/genericDialog';
import { deleteProgramTrack } from '../../../services/programTrack.service';
import CustomTable from '../../../components/CustomTable';
import AddProgramTrack from '../../../components/Modals/AddProgramTrack/AddProgramTrack';
import { UserModel } from '../../../store/user/userModel';
import { useAppSelector } from '../../../store/store';
import { getMultipleUserInfoByUserIds } from '../../../services/user.service';
import { useNavigate } from 'react-router';
import { shouldShowButton } from '../dashboard/dashboardAccessControl';
import { programTrackTypes } from '../../../utils/programTrackEnums';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';

interface IProgramTrackModelToRender {
  careerPathName: string;
  curriculumName: string;
  trainerName: string;
  id?: string;
  name: string;
  type: string;
  location: string;
  description: string;
  time: string;
  startTime: string;
  endTime: string;
  trainerId: string;
  curriculumId: string;
  careerPathId: string;
  milestones: any;
  capacity: number | null;
  enrolled: number | null;
  requirements: string | null;
}
const currentTime = moment.tz('America/New_York');
const formattedTime = currentTime.format('YYYY-MM-DD');

function getRowId(row: any) {
  return row.name + row.careerPath + row.curriculum + row.startTime + row.endTime;
}

const getProgramTrackList = async (programTracks: Array<IProgramTrack>): Promise<Array<IProgramTrackModelToRender>> => {
  const trainerIds = [];
  for (const programTrack of programTracks) {
    trainerIds.push(programTrack?.trainerId || '');
  }
  const staffInfo = await getMultipleUserInfoByUserIds(trainerIds);

  const programTrackRow: Array<IProgramTrackModelToRender> = [];
  for (const programTrack of programTracks) {
    programTrack.trainerInfo = staffInfo.find((data: any) => data.id === programTrack.trainerId) || '';
    programTrackRow.push({
      name: programTrack.name,
      careerPathName: programTrack.careerPathInfo?.name || '',
      curriculumName: programTrack.curriculumName,
      trainerName: programTrack.trainerInfo
        ? `${programTrack.trainerInfo.firstName} ${programTrack.trainerInfo.lastName}`
        : '',
      startTime: programTrack.startTime ? convertTimeStampToDate(programTrack.startTime).format('MM/DD/YYYY') : '-',
      endTime: programTrack.endTime ? convertTimeStampToDate(programTrack.endTime).format('MM/DD/YYYY') : '-',
      id: programTrack.id,
      type: programTrack.type,
      location: programTrack.location,
      description: programTrack.description,
      requirements: programTrack?.requirements || '',
      capacity: programTrack?.capacity || null,
      time: programTrack.time,
      careerPathId: programTrack.careerPathId,
      curriculumId: programTrack.curriculumId,
      trainerId: programTrack?.trainerId || '',
      milestones: programTrack.milestones,
      enrolled: programTrack?.enrolled || 0,
    });
  }
  return programTrackRow;
};
const getEditData = (rowData: any) => {
  const params = {
    name: rowData.name,
    careerPathName: rowData.careerPathName,
    curriculumName: rowData.curriculumName,
    trainerName: rowData.trainerName,
    id: rowData.id,
    type: rowData.type,
    location: rowData.location,
    description: rowData.description,
    requirements: rowData.requirements,
    capacity: rowData.capacity,
    time: rowData.time,
    careerPathId: rowData.careerPathId,
    curriculumId: rowData.curriculumId,
    trainerId: rowData.trainerId,
    milestones: rowData.milestones,
    enrolled: rowData.enrolled,
  };
  if (rowData.type === programTrackTypes.COHORT.value) {
    return rowData;
  } else if (rowData.type === programTrackTypes.ROLLING.value) {
    return { ...params };
  }
};
const ProgramTrackList = () => {
  const navigate = useNavigate();
  const PAGE_SIZE = 10;
  const [loading, setLoading] = React.useState(false);
  const [showCreateProgramTrackModal, setshowCreateProgramTrackModal] = React.useState(false);
  const [programTracks, setProgramTracks] = React.useState<Array<IProgramTrackModelToRender>>([]);
  const [sortModel, setSortModel] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const [searchPaginationModel, setSearchPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [isSearchMode, setIsSearchMode] = React.useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [deleteProgramTrackId, setDeleteProgramTrackId] = useState(String);
  const [activeIds, setActiveIds] = useState<number[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [shouldFetch, setShouldFetch] = useState(false);
  const [programTrackMetaInfo, setProgramTrackMetaInfo] = React.useState<IProgramTrackMetaInfo>();
  const [editMode, setEditMode] = useState({
    isEditMode: false,
    editData: {
      name: '',
      careerPathName: '',
      curriculumName: '',
      trainerName: '',
      type: '',
      startTime: null,
      endTime: null,
      time: '',
      description: '',
      location: '',
      id: '',
      milestones: [],
    },
  });
  const user: UserModel = useAppSelector((state: any) => state.user.user);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      type: 'string',
      sortable: true,
      width: 300,
      renderCell: (params: any) => {
        return (
          <Grid container justifyContent={'left'} alignItems={'center'}>
            <Typography variant='button' sx={{ textTransform: 'capitalize', lineHeight: 1.5 }}>
              {params.row.name}
            </Typography>
          </Grid>
        );
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      type: 'string',
      sortable: true,
      width: 300,
      renderHeader: (params: any) => {
        return CreateColumnHeader(params.colDef.headerName);
      },
      renderCell: (params: any) => {
        if (params.row.type === programTrackTypes.COHORT.value) {
          return CreateColumnCell(
            params.row.type,
            COLORS.palette.white,
            undefined,
            2,
            COLORS.palette.gray,
            COLORS.palette.lightGray
          );
        } else {
          return CreateColumnCell(
            params.row.type,
            COLORS.palette.white,
            undefined,
            2,
            COLORS.palette.lightRed,
            COLORS.palette.red
          );
        }
      },
    },
    {
      field: 'careerPathName',
      headerName: 'Career Path',
      type: 'string',
      sortable: true,
      width: 200,
      renderHeader: (params: any) => {
        return CreateColumnHeader(params.colDef.headerName);
      },
      renderCell: (params: any) => {
        return CreateColumnCell(
          params.row.careerPathName,
          COLORS.palette.white,
          undefined,
          2,
          COLORS.palette.lightOrange,
          COLORS.palette.orange
        );
      },
    },
    {
      field: 'curriculumName',
      headerName: 'Curriculum',
      type: 'string',
      sortable: true,
      width: 200,
      renderHeader: (params: any) => {
        return CreateColumnHeader(params.colDef.headerName);
      },
      renderCell: (params: any) => {
        return CreateColumnCell(
          params.row.curriculumName ? params.row.curriculumName : '...',
          COLORS.palette.white,
          undefined,
          2,
          COLORS.palette.lightGreen,
          COLORS.palette.green
        );
      },
    },
    {
      field: 'startTime',
      headerName: 'Start Date',
      type: 'string',
      sortable: true,
      width: 200,
      renderHeader: (params: any) => {
        return CreateColumnHeader(params.colDef.headerName);
      },
      renderCell: (params: any) => {
        return CreateColumnCell(
          params.row.startTime.toString() ? params.row.startTime.toString() : '---',
          COLORS.palette.white,
          COLORS.palette.purple,
          2,
          COLORS.palette.lightPurple,
          COLORS.palette.purple
        );
      },
    },
    {
      field: 'endTime',
      headerName: 'End Date',
      type: 'string',
      sortable: true,
      width: 200,
      renderHeader: (params: any) => {
        return CreateColumnHeader(params.colDef.headerName);
      },
      renderCell: (params: any) => {
        return CreateColumnCell(
          params.row.endTime.toString() ? params.row.endTime.toString() : '---',
          COLORS.palette.white,
          COLORS.palette.purple,
          2,
          COLORS.palette.lightPurple,
          COLORS.palette.purple
        );
      },
    },
    {
      field: 'enrollmentCount',
      headerName: 'No. of Enrollments',
      type: 'string',
      sortable: true,
      width: 200,
      renderHeader: (params: any) => {
        return CreateColumnHeader(params.colDef.headerName);
      },
      renderCell: (params: any) => {
        return CreateColumnCell(
          params.row.enrolled,
          COLORS.palette.white,
          COLORS.palette.blue,
          2,
          COLORS.palette.lightBlue,
          COLORS.palette.blue
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'string',
      sortable: true,
      width: 200,
      renderHeader: (params: any) => {
        return CreateColumnHeader(params.colDef.headerName);
      },
      renderCell: (params: any) => {
        return (
          <Grid container justifyContent={'center'} alignItems={'center'} zIndex={1}>
            <Tooltip title='Edit' arrow>
              <IconButton
                color='primary'
                onClick={(e) => {
                  e.stopPropagation();
                  setEditMode({ ...editMode, isEditMode: true, editData: getEditData(params.row) });
                  setshowCreateProgramTrackModal(!showCreateProgramTrackModal);
                }}
                disabled={
                  moment(params.row.startTime).tz('America/New_York').isBefore(formattedTime) ||
                  !shouldShowButton('createProgramTrack', user?.role)
                }>
                <EditOutlined
                  style={{
                    color:
                      moment(params.row.startTime).tz('America/New_York').isBefore(formattedTime) ||
                      !shouldShowButton('createProgramTrack', user?.role)
                        ? COLORS.theme.lightPrimaryColor
                        : COLORS.theme.primaryColor,
                  }}
                />
              </IconButton>
            </Tooltip>

            <Tooltip title='Remove' arrow>
              <IconButton
                color='error'
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteProgramTrackId(params.row.id);
                  setDeleteAlert(true);
                }}
                disabled={
                  moment(params.row.startTime).tz('America/New_York').isBefore(formattedTime) ||
                  !shouldShowButton('createProgramTrack', user?.role)
                }>
                <DeleteOutline
                  style={{
                    color:
                      moment(params.row.startTime).tz('America/New_York').isBefore(formattedTime) ||
                      !shouldShowButton('createProgramTrack', user?.role)
                        ? COLORS.palette.lightRed
                        : COLORS.palette.red,
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        );
      },
    },
  ];

  const loadData = async () => {
    try {
      setLoading(true);
      setIsSearchMode(false);
      setSearchQuery('');
      const result = await getProgramTracksByQuery(activeIds, paginationModel.page + 1, user, sortModel);
      if (result?.programTracks.length) {
        setProgramTrackMetaInfo(result);
        const programTrackRow = await getProgramTrackList(result.programTracks);
        setProgramTracks(programTrackRow);
      } else {
        setProgramTrackMetaInfo(result);
        setProgramTracks([]);
      }
      setLoading(false);
    } catch (err: any) {
      toast.error(err.message);
      setLoading(false);
    }
  };

  const handleAddProgramTrack = () => {
    setShouldFetch(true);
    loadData();
  };

  const dummyCallAddProgramTrack = async () => {
    await pseudoAddProgramTrack();
  };

  React.useEffect(() => {
    if (!isSearchMode) {
      loadData();
    }
    // eslint-disable-next-line
  }, [paginationModel.page, shouldFetch, activeIds, editMode.isEditMode, editMode.editData, sortModel]);

  const handleDelete = async () => {
    try {
      await deleteProgramTrack(deleteProgramTrackId);
      loadData();
    } catch (err: any) {
      console.error(err?.response?.data?.message || err?.message);
    }
  };

  const handleSearch = async () => {
    if (searchQuery) {
      setLoading(true);
      setIsSearchMode(true);
      const result: any = await searchProgramTracks(
        searchQuery.toLowerCase(),
        searchPaginationModel.page + 1,
        activeIds,
        user
      );
      if (result.programTracks.length > 0) {
        setProgramTrackMetaInfo(result);
        const programTrackRow = await getProgramTrackList(result.programTracks);
        programTrackRow.sort((a, b) => {
          const aTime = a.startTime ? new Date(a.startTime).getTime() : 0;
          const bTime = b.startTime ? new Date(b.startTime).getTime() : 0;
          return aTime - bTime;
        });
        setProgramTracks(programTrackRow);
      } else {
        setProgramTrackMetaInfo(result);
        setProgramTracks([]);
      }
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (isSearchMode) {
      handleSearch();
    } else {
      loadData();
    }
    // eslint-disable-next-line
  }, [searchPaginationModel.page, activeIds]);

  React.useEffect(() => {
    if (!searchQuery && isSearchMode) {
      loadData();
    }
    // eslint-disable-next-line
  }, [searchQuery]);

  const handleSortModelChange = (newSortModel: any) => {
    setSortModel(newSortModel);
  };

  return (
    <Box sx={{ width: '100%', height: 500 }}>
      <CustomTable
        primaryButtonLable='Add Program Track'
        primaryButtonAction={() => {
          setEditMode({ ...editMode, isEditMode: false });
          setshowCreateProgramTrackModal(!showCreateProgramTrackModal);
          dummyCallAddProgramTrack();
        }}
        disabled={!shouldShowButton('createProgramTrack', user?.role)}
        onFilterListChange={(list: any) => setActiveIds(list)}
        rows={programTracks}
        columns={columns}
        loading={loading}
        getRowId={getRowId}
        rowCount={programTrackMetaInfo?.totalRecords || 0}
        paginationModel={isSearchMode ? searchPaginationModel : paginationModel}
        onPaginationModelChange={isSearchMode ? setSearchPaginationModel : setPaginationModel}
        filterList={ProgramTrackListStatuses}
        title='Program Tracks'
        onChangeSearchQuery={(query: string) => {
          setSearchQuery(query);
        }}
        onRowClick={(params: any) => {
          navigate('../program-track-info', { state: { programTrackId: params.row.id } });
        }}
        handleSearch={() => handleSearch()}
        searchQuery={searchQuery}
        onSortModelChange={handleSortModelChange}
        sortModel={sortModel}
      />

      <GenericDialog
        onClose={() => setDeleteAlert(false)}
        dialogTitle='Are you sure?'
        agreeBtnText='Delete'
        disagreeBtnText='Cancel'
        agreeFunc={() => handleDelete()}
        open={deleteAlert}
      />
      <AddProgramTrack
        open={showCreateProgramTrackModal}
        data={editMode.isEditMode ? editMode.editData : null}
        mode={editMode.isEditMode ? 'EDIT' : 'CREATE'}
        onClose={() => setshowCreateProgramTrackModal(!showCreateProgramTrackModal)}
        onAddProgramTrack={handleAddProgramTrack}
      />
    </Box>
  );
};

export default ProgramTrackList;
