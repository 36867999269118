import React, { useEffect, useState } from 'react';
import BasicInfo from './basicInfo';
import OtherInfo from './otherInfo';
import UploadDocs from './uploadDocs';
import { Step, StepLabel, Stepper } from '@mui/material';
import dayjs from 'dayjs';
import {
  ClientStepper,
  IBasicDetails,
  IOtherDetails,
  IUploadDocs,
  emptyBasicDetails,
  emptyOtherDetails,
  emptyUploadDocs,
} from '../utils';
import Confirmation from './confirmation';

interface ClientFormProps {
  onSubmit: Function;
  isSelf: boolean;
  isSignupComplete?: boolean;
  customUrlName?: string;
}

const ClientForm = (props: ClientFormProps) => {
  const { onSubmit, isSelf, isSignupComplete, customUrlName } = props;
  const [activeStep, setActiveStep] = useState<number>(0);
  const [basicInfo, setBasicInfo] = useState<IBasicDetails>(emptyBasicDetails);
  const [otherInfo, setOtherInfo] = useState<IOtherDetails>(emptyOtherDetails);
  const [selectedSessionInfo, setSelectedSessionInfo] = useState<IUploadDocs>(emptyUploadDocs);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [backMode, setBackMode] = React.useState({
    isBackMode: false,
    BackData: {},
  });

  const handleNext = async (data: any) => {
    if (activeStep === 0) setBasicInfo(data);
    if (activeStep === 1) setOtherInfo(data);
    if (activeStep === 2) {
      if (isSelf) {
        setLoading(true);
        setSelectedSessionInfo({
          urls: data.files,
          infoSessionIds: data.infoSessionIds,
          notes: [],
        });
        let payload = {
          ...basicInfo,
          ...otherInfo,
          urls: data.files,
          infoSessionIds: data.infoSessionIds,
          isUserAgreeToTerms: data.isUserAgreeToTerms,
          isAgreeToTextMessaging: data.isAgreeToTextMessaging,
          notes: [],
          dob: dayjs(basicInfo.dob).format('MM/DD/YYYY'),
        };
        await onSubmit(payload);
        if (isSignupComplete) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        return;
      } else {
        let notes = {};
        if (data.title || data.description) {
          notes = {
            category: data.category,
            title: data.title,
            description: data.description,
          };
        }
        let payload = {
          ...basicInfo,
          ...otherInfo,
          urls: data.files,
          notes: data.title || data.description || data.category ? [notes] : [],
          dob: dayjs(basicInfo.dob).format('MM/DD/YYYY'),
        };

        onSubmit(payload);
        return;
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  useEffect(() => {
    if (isSignupComplete) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [isSignupComplete]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setBackMode({
      ...backMode,
      isBackMode: true,
      BackData: activeStep === 2 ? otherInfo : activeStep === 3 ? selectedSessionInfo : basicInfo,
    });
  };

  const showContentBySteps = (step: number) => {
    if (step === 1)
      return (
        <BasicInfo
          handleNext={handleNext}
          handleBack={handleBack}
          isBackMode={backMode.isBackMode ? true : false}
          data={backMode.isBackMode ? backMode.BackData : null}
          isSelf={isSelf}
        />
      );
    if (step === 2)
      return (
        <OtherInfo
          handleNext={handleNext}
          handleBack={handleBack}
          isBackMode={backMode.isBackMode ? true : false}
          data={backMode.isBackMode ? backMode.BackData : null}
          isSelf={isSelf}
        />
      );
    if (step === 3)
      return (
        <UploadDocs
          handleNext={handleNext}
          handleBack={handleBack}
          isSelf={isSelf}
          isBackMode={backMode.isBackMode ? true : false}
          data={backMode.isBackMode ? backMode.BackData : null}
          loading={loading}
          fullName={`${basicInfo.firstName} ${basicInfo.lastName}`}
          customUrlName={customUrlName}
        />
      );
    if (step === 4)
      return <Confirmation infoSessionIds={selectedSessionInfo.infoSessionIds} firstName={basicInfo.firstName} />;
  };

  return (
    <>
      <Stepper activeStep={activeStep} alternativeLabel>
        {ClientStepper(isSelf, customUrlName).map((item) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};

          return (
            <Step key={item.id} {...stepProps}>
              <StepLabel {...labelProps}>{item.title}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <>{showContentBySteps(activeStep + 1)}</>
    </>
  );
};

export default ClientForm;
