import { Grid } from '@mui/material';
import React from 'react';
import { DashboardCard } from '../DashboardCard/DashboardCard';
import { ICommonClient, getClientsLive } from '../../services/client.service';
import { toast } from 'react-toastify';
import { ClientStatus } from '../../utils/clientStatus';
import { UserRolesEnum } from '../../utils/rolesEnum';
import { useNavigate } from 'react-router-dom';
import { adminTypeEnum } from '../../utils/adminTypes';

interface IProspectiveClientsDashboardCardProps {
  userRole: string;
  adminType: string;
}

export default function ProspectiveClientsDashboardCard(props: IProspectiveClientsDashboardCardProps) {
  const { adminType, userRole } = props;
  const navigate = useNavigate();
  const [prospectiveClients, setProspectiveClients] = React.useState<Array<ICommonClient>>([]);
  const [showLoader, setShowLoader] = React.useState<boolean>(false);

  const checkCardPermission =
    userRole === UserRolesEnum.CASE_MANAGER ||
    userRole === UserRolesEnum.SUPER_ADMIN ||
    (userRole === UserRolesEnum.ADMIN && !(adminType === adminTypeEnum.EMPLOYMENT));

  const loadProspectiveClients = () => {
    try {
      setShowLoader(true);
      const unsubscribe = getClientsLive(ClientStatus.PROSPECTIVE, (data: any) => {
        const filteredClients = data.filter((i: any) => i?.account != 'deleted');
        setProspectiveClients(filteredClients);
        setShowLoader(false);
      });
      return () => {
        unsubscribe();
      };
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  React.useEffect(() => {
    if (checkCardPermission) loadProspectiveClients();
    // eslint-disable-next-line
  }, [userRole]);

  return checkCardPermission ? (
    <Grid item lg={6} xs={12}>
      <DashboardCard
        isLoading={showLoader}
        label='Prospective Clients'
        desc='List of prospective clients'
        list={prospectiveClients}
        onItemClick={(item: any) => {
          navigate('../prospective-client-info', { state: { clientId: item.id } });
        }}
      />
    </Grid>
  ) : null;
}
