import React, { useEffect, useState } from 'react';
import { Box, Chip, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import CustomTable from '../../../components/CustomTable';
import { IPaginationModel } from '../../../store/pagination/paginationModel';
import { CreateColumnHeader } from '../../../components/CreateColumnHeader/CreateColumnHeader';
import { CreateColumnCell } from '../../../components/CreateColumnCell/CreateColumnCell';
import { COLORS } from '../../../utils/colors';
import { DeleteOutline, PersonAdd } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import GenericDialog from '../../../components/Dialog/genericDialog/genericDialog';
import { toast } from 'react-toastify';
import {
  ICLientMetaInfo,
  ICommonClient,
  deleteClient,
  getClientsEmploymentDataByFilter,
  getClientsEmploymentPageData,
  searchClientsEmployment,
} from '../../../services/client.service';
import { ClientEmploymentList, getEmploymentStatusColors, renderClientsEmploymentStatus } from './utils';
import { ClientStatus } from '../../../utils/clientStatus';
import { EMAIL_REGEX, MOBILE_REGEX } from '../../../utils/regex';
import FilterModal from '../../../components/Modals/FilterModal/FilterModal';
import { updateWcl } from '../../../services/workingClientList.service';
import { UserModel } from '../../../store/user/userModel';
import { useAppSelector } from '../../../store/store';

const ClientEmploymentPage = () => {
  const navigate = useNavigate();
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState<any>({});
  const [clientMetaInfo, setClientMetaInfo] = React.useState<ICLientMetaInfo>();
  const [clients, setClients] = React.useState<Array<ICommonClient>>([]);
  const [sortModel, setSortModel] = useState([]);
  const PAGE_SIZE = 10;
  const [paginationModel, setPaginationModel] = useState<IPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const [searchPaginationModel, setSearchPaginationModel] = useState<IPaginationModel>({
    page: 0,
    pageSize: 10,
  });
  const [filterPaginationModel, setFilterPaginationModel] = useState<IPaginationModel>({
    page: 0,
    pageSize: 10,
  });
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchQueryType, setSearchQueryType] = React.useState<string>('');
  const [isSearchMode, setIsSearchMode] = useState<boolean>(false);
  const [isFilterMode, setIsFilterMode] = useState<boolean>(false);
  const [activeIds, setActiveIds] = useState<number[]>([]);
  const [filterModal, setFilterModal] = React.useState(false);
  const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: string[] }>({});
  const [selectedRowsData, setSelectedRowsData] = useState<Array<string>>([]);

  const handleCheckboxChange = (filterId: string, optionId: string) => {
    setSelectedOptions((prevState) => {
      const currentSelections = prevState[filterId] || [];
      const isSelected = currentSelections.includes(optionId);

      const newSelections = isSelected
        ? currentSelections.filter((id) => id !== optionId)
        : [...currentSelections, optionId];

      return {
        ...prevState,
        [filterId]: newSelections,
      };
    });
  };
  //render column header
  const renderColHeader = (headerName: any) => {
    return (
      <Grid container sx={{ justifyContent: 'center', alignItems: 'center', width: 300 }} justifyContent={'center'}>
        <Typography variant='button' textTransform={'capitalize'}>
          {headerName}
        </Typography>
      </Grid>
    );
  };

  //render column cells
  const renderColCell = (value: string, background?: string, color?: string) => {
    return (
      <Grid container justifyContent={'center'} alignItems={'center'}>
        <Chip
          size='small'
          sx={{
            background: background,
            color: color,
          }}
          label={
            <Typography
              variant='button'
              sx={{
                fontSize: '10px',
                textTransform: 'uppercase',
                lineHeight: 1.5,
              }}>
              {value}
            </Typography>
          }
        />
      </Grid>
    );
  };
  const loadData = async () => {
    try {
      setLoading(true);
      setSearchQuery('');
      setIsSearchMode(false);
      setIsFilterMode(false);
      const unsubscribe: any = getClientsEmploymentPageData(
        paginationModel.page + 1,
        activeIds,
        sortModel,
        (result: any) => {
          if (result.clients?.length) {
            setClientMetaInfo(result);
            setClients(result.clients);
          } else {
            setClientMetaInfo(result);
            setClients([]);
          }
          setLoading(false);
        }
      );
      return () => {
        unsubscribe();
      };
    } catch (err: any) {
      toast.error(err.message);
      setLoading(false);
    }
  };

  const handleFilter = async () => {
    try {
      setLoading(true);
      setSearchQuery('');
      setIsFilterMode(true);
      setIsSearchMode(false);
      const unsubscribe: any = getClientsEmploymentDataByFilter(
        filterPaginationModel.page + 1,
        selectedOptions,
        sortModel,
        (result: any) => {
          if (result.clients?.length) {
            setClientMetaInfo(result);
            setClients(result.clients);
          } else {
            setClientMetaInfo(result);
            setClients([]);
          }
          setLoading(false);
        }
      );
      return () => {
        unsubscribe();
      };
    } catch (err: any) {
      toast.error(err.message);
      setLoading(false);
    }
  };

  useEffect(
    () => {
      loadData();
    },
    // eslint-disable-next-line
    [paginationModel.page, activeIds, sortModel]
  );

  useEffect(() => {
    if (!searchQuery) {
      loadData();
    }
    // eslint-disable-next-line
  }, [searchQuery]);

  const handleDelete = async () => {
    try {
      setLoading(true);
      await deleteClient(selectedClient?.id);
      setLoading(false);
      loadData();
      toast.success('Client Deleted Successfully!');
    } catch (err: any) {
      setLoading(false);
      toast.error('Error Deleting Client!');
      console.error(err?.response.data.message || err?.message);
    }
  };

  const handleSearch = async () => {
    if (searchQuery) {
      setLoading(true);
      setIsSearchMode(true);
      setIsFilterMode(false);
      const result: any = await searchClientsEmployment(
        searchQuery.toLowerCase(),
        searchQueryType,
        searchPaginationModel.page + 1
      );
      if (result.clients?.length) {
        setClientMetaInfo(result);
        setClients(result.clients);
      } else {
        setClientMetaInfo(result);
        setClients([]);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line
  }, [searchPaginationModel.page]);

  useEffect(() => {
    if (isFilterMode) {
      handleFilter();
    }
    // eslint-disable-next-line
  }, [filterPaginationModel.page]);

  const columns = [
    {
      field: 'firstName',
      headerName: 'Client',
      type: 'string',
      sortable: true,
      width: 250,
      renderCell: (params: any) => `${params.row.firstName} ${params.row.lastName}`,
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'string',
      sortable: false,
      width: 300,
      renderHeader: (params: any) => {
        return renderColHeader(params.colDef.headerName);
      },
      renderCell: (params: any) => {
        const clientsEmpStatus = renderClientsEmploymentStatus(params.row);
        return renderColCell(
          clientsEmpStatus,
          getEmploymentStatusColors(clientsEmpStatus)?.bgColor,
          getEmploymentStatusColors(clientsEmpStatus)?.textColor
        );
      },
    },
    {
      field: 'employmentLiaison',
      headerName: 'Employment Liaison',
      type: 'string',
      sortable: true,
      width: 250,
      renderCell: (params: any) => {
        return CreateColumnCell(
          params.row.employmentLiaisonName ? params.row.employmentLiaisonName : '--',
          COLORS.palette.white,
          undefined,
          2,
          COLORS.palette.lightBlue,
          COLORS.palette.blue
        );
      },
    },
    {
      field: 'interviewCount',
      headerName: 'Interview Count',
      type: 'number',
      sortable: true,
      width: 300,
      renderHeader: (params: any) => {
        return CreateColumnHeader(params.colDef.headerName);
      },
      renderCell: (params: any) => {
        return CreateColumnCell(
          params.row?.scheduledJobInterview?.length || 0,
          COLORS.palette.white,
          undefined,
          2,
          COLORS.palette.lightGreen,
          COLORS.palette.green
        );
      },
    },
    {
      headerName: 'Actions',
      sortable: false,
      width: 100,
      renderHeader: (params: any) => {
        return CreateColumnHeader(params.colDef.headerName);
      },
      renderCell: (params: any) => (
        <Box display={'flex'} gap={2}>
          <Tooltip title='Delete' arrow>
            <IconButton
              color='error'
              onClick={(e) => {
                e.stopPropagation();
                setSelectedClient(params.row);
                setDeleteAlert(true);
              }}>
              <DeleteOutline
                style={{
                  color: COLORS.palette.red,
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handleSortModelChange = (newSortModel: any) => {
    setSortModel(newSortModel);
  };

  const handleRowSelection = (selectedIds: Array<string>) => {
    setSelectedRowsData(selectedIds);
  };

  const addClientToWcl = async () => {
    await updateWcl({ clientIds: selectedRowsData }, 'add', user?.id || '');
    setSelectedRowsData([]);
  };

  return (
    <Box>
      <CustomTable
        rows={clients}
        columns={columns}
        filterList={ClientEmploymentList}
        loading={loading}
        disbaleMultiSelect={true}
        onFilterListChange={(list: any) => setActiveIds(list)}
        paginationModel={isSearchMode ? searchPaginationModel : isFilterMode ? filterPaginationModel : paginationModel}
        onPaginationModelChange={
          isSearchMode ? setSearchPaginationModel : isFilterMode ? setFilterPaginationModel : setPaginationModel
        }
        rowCount={clientMetaInfo?.totalRecords || 0}
        onRowClick={(params: any) => {
          if (String(params.row.status).toLowerCase() === ClientStatus.PROSPECTIVE) {
            navigate('../prospective-client-info', {
              state: { clientId: params.row.id },
            });
          } else {
            navigate('../clientInfo', {
              state: { clientId: params.row.id, programTrackId: params.row.programTrackInfo?.id || null },
            });
          }
        }}
        title='Clients Employment'
        showFilterButton={true}
        filterButtonAction={() => setFilterModal(true)}
        primaryButtonIcon={<PersonAdd />}
        primaryButtonLable='Create Client'
        primaryButtonAction={() => navigate('/admin/createClient')}
        onChangeSearchQuery={(val: string) => {
          setSearchQuery(val);
          if (EMAIL_REGEX.test(val)) {
            setSearchQueryType('email');
          } else if (MOBILE_REGEX.test(val)) {
            setSearchQueryType('mobile');
          } else {
            setSearchQueryType('name');
          }
        }}
        handleSearch={() => handleSearch()}
        searchQuery={searchQuery}
        searchBoxPlaceHolder='Type First Name to Search'
        onSortModelChange={handleSortModelChange}
        sortModel={sortModel}
        onRowSelectionModelChange={handleRowSelection}
        rowSelectionModel={selectedRowsData}
        showAddWclBtn={true}
        addClientToWcl={addClientToWcl}
      />
      <GenericDialog
        onClose={() => setDeleteAlert(false)}
        dialogTitle='Are you sure?'
        agreeBtnText='Delete'
        disagreeBtnText='Cancel'
        agreeFunc={() => handleDelete()}
        open={deleteAlert}
      />
      <FilterModal
        open={filterModal}
        onClose={() => setFilterModal(false)}
        handleFilter={handleFilter}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        handleCheckboxChange={handleCheckboxChange}
        isClientList={false}
      />
    </Box>
  );
};

export default ClientEmploymentPage;
