import React from 'react';
import {
  Box,
  Grid,
  TextField,
  Typography,
  IconButton,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { COLORS } from '../../../utils/colors';
import { LoadingButton } from '../../LoadingButton/LoadingButton';
import { useAppSelector } from '../../../store/store';
import dayjs from 'dayjs';
import { Formik, type FormikHelpers } from 'formik';
import * as yup from 'yup';
import { UserModel } from '../../../store/user/userModel';
import { isNumber } from '../../../utils/common';
import { WriteModeEnum } from '../../../utils/writeModeEnum';
import { InfoSessionEditModel } from '../../../store/infoSession/infoSessionModel';
import { DateTimePicker, LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { infoSessionTypes, infoSessionTypesRender } from '../../../utils/infoSessionTypeEnums';
import { addNewInfoSession, editInfoSession, isCustomUrlNameUnique } from '../../../services/infoSession.service';
import { NO_SPACE_REGEX } from '../../../utils/regex';

export interface IInfoSession {
  title: string;
  location: string;
  description: string;
  hra: string;
  sessionType: string;
  meetingLink?: string;
  repeatWeekly: boolean;
  repeatWeeks?: number;
}

interface IAddInfoSessionProps {
  open: boolean;
  onClose: () => void;
  onAddInfoSession: () => void;
  mode: string;
  data: any;
}

const validationSchema = yup.object().shape({
  title: yup.string().required('Program is required'),
  description: yup.string().required('Description is required'),
  dateTime: yup.number().required('Date and Time is required'),
  sessionType: yup.string().required('Session Type is required'),
  hra: yup.string().required('HRA Liaison Site is required'),
  customUrlName: yup
    .string()
    .required('Custom Url Name is required')
    .matches(NO_SPACE_REGEX, 'Custom Url Name cannot contain spaces')
    .test('is-unique', 'Custom Url Name must be unique', async function (value) {
      if (value) {
        return await isCustomUrlNameUnique(value);
      }
      return true;
    }),
  meetingLink: yup.string().when('sessionType', (typeValue) => {
    if (typeValue[0] === infoSessionTypes.VIRTUAL) {
      return yup.string().required('Meeting Link is Required in Virtual Info-Sessions');
    } else {
      return yup.string().notRequired();
    }
  }),
  location: yup.string().when('type', (typeValue) => {
    if (typeValue[0] === infoSessionTypes.IN_PERSON) {
      return yup.string().required('Location is Required in In Person Info-Sessions');
    } else {
      return yup.string().notRequired();
    }
  }),
  repeatWeekly: yup.boolean().optional(),
  repeatWeeks: yup.number().when('repeatWeekly', (repeatWeeklyValue) => {
    if (repeatWeeklyValue[0]) {
      return yup
        .number()
        .required('Repeat Weeks is required when Repeat Weekly is selected')
        .test({
          name: 'mustBeNumber',
          message: 'Repeat Weeks must be a valid number',
          test: isNumber,
        })
        .max(24, 'Repeat Weeks must be less than or equal to 24');
    } else {
      return yup.number().nullable();
    }
  }),
});

const AddInfoSessionModal = (props: IAddInfoSessionProps) => {
  const { open, onClose, mode, data, onAddInfoSession } = props;
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const initialValues: InfoSessionEditModel = {
    id: data?.id || '',
    title: data?.title || '',
    description: data?.description || '',
    sessionType: data?.sessionType || '',
    hra: data?.hra || '',
    createdBy: user?.id || '',
    location: data?.location || '',
    dateTime: data ? new Date(data.dateTime).getTime() : dayjs().valueOf(),
    repeatWeekly: data?.repeatWeekly || false,
    meetingLink: data?.meetingLink || '',
    repeatWeeks: data?.repeatWeeks || 0,
    numClients: data?.numClients || 0,
    isAttendanceMarked: data?.isAttendanceMarked || false,
    customUrlName: data?.customUrlName || '',
  };

  const handleSubmit = async (values: InfoSessionEditModel, { setSubmitting }: FormikHelpers<InfoSessionEditModel>) => {
    try {
      const payload: any = { ...values };
      if (mode === WriteModeEnum.CREATE) {
        delete payload.id;
        await addNewInfoSession(payload);
      } else {
        await editInfoSession(payload);
      }
      onAddInfoSession();
    } catch (error) {
      console.error(error);
    }
    setSubmitting(false);
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { borderRadius: '14px' } }}>
      <DialogTitle height={70} borderBottom={`1px solid ${COLORS.palette.borderColor}`}>
        <Grid container direction='row' justifyContent='space-between' alignItems='center' marginBottom='20px'>
          <Typography variant='h6'>
            {props.mode === WriteModeEnum.CREATE ? 'Create Info-Session' : 'Update Info-Session'}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          isInitialValid={false}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
            setFieldValue,
          }) => {
            return (
              <Box py={2}>
                <Grid container spacing={2} pb={2}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      name='title'
                      label='Program'
                      size='small'
                      type='text'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title ? errors.title : ''}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      name='hra'
                      label='HRA Liaison Site'
                      size='small'
                      type='text'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.hra}
                      error={Boolean(touched.hra && errors.hra)}
                      helperText={touched.hra && errors.hra ? errors.hra : ''}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      select
                      label='Info-Session Type'
                      size='small'
                      name='sessionType'
                      value={values.sessionType}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.sessionType && Boolean(errors.sessionType)}
                      helperText={touched.sessionType && errors.sessionType}>
                      {infoSessionTypesRender.map((option: any) => (
                        <MenuItem key={option.id} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {values.sessionType === infoSessionTypes.VIRTUAL ? (
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        name='meetingLink'
                        label='Meeting Link'
                        size='small'
                        type='text'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.meetingLink}
                        error={Boolean(touched.meetingLink && errors.meetingLink)}
                        helperText={touched.meetingLink && errors.meetingLink ? errors.meetingLink : ''}
                      />
                    </Grid>
                  ) : null}
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      name='description'
                      label='Description'
                      size='small'
                      type='text'
                      multiline
                      rows={3}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description ? errors.description : ''}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{ okButtonLabel: 'Select' }}>
                      <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                        <DateTimePicker
                          disablePast
                          label='Date and Time'
                          slotProps={{ textField: { size: 'small', error: false, fullWidth: true } }}
                          name='dateTime'
                          defaultValue={dayjs(values?.dateTime) || null}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          onChange={(date: string | number | Date | dayjs.Dayjs | null | undefined) => {
                            const formattedDate = dayjs(date).valueOf();
                            setFieldValue('dateTime', formattedDate);
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                  {values.sessionType === infoSessionTypes.IN_PERSON ? (
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        name='location'
                        label='Location'
                        size='small'
                        type='text'
                        multiline
                        rows={2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.location}
                        error={Boolean(touched.location && errors.location)}
                        helperText={touched.location && errors.location ? errors.location : ''}
                      />
                    </Grid>
                  ) : null}
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      name='customUrlName'
                      label='Custom Url Name'
                      size='small'
                      type='text'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.customUrlName}
                      error={Boolean(touched.customUrlName && errors.customUrlName)}
                      helperText={touched.customUrlName && errors.customUrlName ? errors.customUrlName : ''}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControlLabel
                      control={<Checkbox name='repeatWeekly' checked={values.repeatWeekly} onChange={handleChange} />}
                      label='Repeat Info-Session Weekly'
                      disabled={mode === WriteModeEnum.EDIT}
                    />
                  </Grid>
                  {values.repeatWeekly ? (
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        name='repeatWeeks'
                        inputMode='numeric'
                        label='Repeat for (weeks)'
                        size='small'
                        type='text'
                        onChange={(event) => {
                          const value = event.target.value;
                          const parsedValue = Number.parseInt(value, 10);
                          setFieldValue('repeatWeeks', isNaN(parsedValue) ? null : parsedValue);
                        }}
                        disabled={mode === WriteModeEnum.EDIT}
                        onBlur={handleBlur}
                        value={values.repeatWeeks}
                        error={Boolean(touched.repeatWeeks && errors.repeatWeeks)}
                        helperText={touched.repeatWeeks && errors.repeatWeeks ? errors.repeatWeeks : ''}
                      />
                    </Grid>
                  ) : null}
                </Grid>

                <Grid container justifyContent={'center'}>
                  <Grid item>
                    <LoadingButton
                      onClick={() => handleSubmit()}
                      label={mode === WriteModeEnum.CREATE ? 'Submit' : 'Update'}
                      loading={isSubmitting}
                      disabled={!isValid}
                      size='medium'
                      type='submit'
                      variant='contained'
                      styles={{ textTransform: 'none' }}
                    />
                  </Grid>
                </Grid>
              </Box>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddInfoSessionModal;
