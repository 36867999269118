import React from 'react';
import { Link } from '@mui/material';

interface ICustomLinkProps {
  text: string;
  fullName: string;
  urlName: string;
}

const CustomNavigationLink = (props: ICustomLinkProps) => {
  const { text, fullName, urlName } = props;
  return (
    <Link
      onClick={() => {
        let url = '';
        if (process.env.REACT_APP_PROJECT_ID === 'nyatlas-test') {
          url = `https://pincctest.com/${urlName}`;
        } else if (process.env.REACT_APP_PROJECT_ID === 'nyatlas-portal') {
          url = `https://pincc.nyc/${urlName}`;
        }

        const applicantName = encodeURIComponent(fullName);
        const finalUrl = `${url}?applicantName=${applicantName}`;

        window.open(finalUrl, '_blank', 'noopener,noreferrer');
      }}
      target='_blank'
      sx={{ cursor: 'pointer' }}
      rel='noopener noreferrer'>
      {text}
    </Link>
  );
};

export default CustomNavigationLink;
