export type RolePermissionType = {
  [key: string]: number[];
};

export const DashboardButton = {
  createClient: 1,
  createInfoSession: 2,
  viewInfoSession: 3,
  startNewProgramTrack: 4,
  inviteProspectiveClient: 5,
  createProgramTrack: 6,
  createNewCurriculum: 7,
  createCareerPath: 8,
  resendInvitationMailToPC: 9,
  createWorkshop: 10,
  viewWorkshops: 11,
  createJob: 12,
  enrollInProgramTrack: 13,
  assignEmploymentLiaison: 14,
  suspendClient: 15,
  assignNavigator: 16,
  accessNotes: 17,
  viewJobs: 18,
  convertProspective: 19,
};

export const RolePermissions: RolePermissionType = {
  caseManager: [
    DashboardButton.inviteProspectiveClient,
    DashboardButton.createClient,
    DashboardButton.viewInfoSession,
    DashboardButton.resendInvitationMailToPC,
    DashboardButton.enrollInProgramTrack,
    DashboardButton.suspendClient,
    DashboardButton.convertProspective,
    DashboardButton.accessNotes,
  ],
  navigator: [
    DashboardButton.inviteProspectiveClient,
    DashboardButton.createClient,
    DashboardButton.viewInfoSession,
    DashboardButton.resendInvitationMailToPC,
    DashboardButton.enrollInProgramTrack,
    DashboardButton.suspendClient,
    DashboardButton.convertProspective,
    DashboardButton.accessNotes,
  ],
  trainer: [DashboardButton.accessNotes, DashboardButton.enrollInProgramTrack],
  admin: [
    DashboardButton.createClient,
    DashboardButton.createInfoSession,
    DashboardButton.viewInfoSession,
    DashboardButton.startNewProgramTrack,
    DashboardButton.inviteProspectiveClient,
    DashboardButton.createNewCurriculum,
    DashboardButton.createProgramTrack,
    DashboardButton.createCareerPath,
    DashboardButton.resendInvitationMailToPC,
    DashboardButton.createWorkshop,
    DashboardButton.viewWorkshops,
    DashboardButton.createJob,
    DashboardButton.enrollInProgramTrack,
    DashboardButton.assignEmploymentLiaison,
    DashboardButton.suspendClient,
    DashboardButton.convertProspective,
    DashboardButton.assignNavigator,
    DashboardButton.accessNotes,
    DashboardButton.viewJobs,
  ],
  superAdmin: [
    DashboardButton.createClient,
    DashboardButton.createInfoSession,
    DashboardButton.viewInfoSession,
    DashboardButton.startNewProgramTrack,
    DashboardButton.inviteProspectiveClient,
    DashboardButton.createNewCurriculum,
    DashboardButton.createProgramTrack,
    DashboardButton.createCareerPath,
    DashboardButton.resendInvitationMailToPC,
    DashboardButton.createWorkshop,
    DashboardButton.viewWorkshops,
    DashboardButton.createJob,
    DashboardButton.enrollInProgramTrack,
    DashboardButton.assignEmploymentLiaison,
    DashboardButton.suspendClient,
    DashboardButton.convertProspective,
    DashboardButton.assignNavigator,
    DashboardButton.accessNotes,
    DashboardButton.viewJobs,
  ],
  employmentLiaison: [
    DashboardButton.createWorkshop,
    DashboardButton.viewWorkshops,
    DashboardButton.createJob,
    DashboardButton.viewJobs,
    DashboardButton.accessNotes,
  ],
};

export const shouldShowButton = (button: keyof typeof DashboardButton, userRole: any): boolean => {
  const allowedButtons = RolePermissions[userRole];
  return allowedButtons?.includes(DashboardButton[button]);
};
