import React, { useEffect, useState, useRef } from 'react';
import { AddCircleOutline, Clear, VisibilityOutlined } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { LoadingButton } from '../LoadingButton/LoadingButton';
import * as yup from 'yup';
import { Formik, type FormikHelpers } from 'formik';
import { getClientInfoById, uploadFiles } from '../../services/client.service';
import { COLORS } from '../../utils/colors';
import { addEmploymentIntakeInfo, getEmploymentIntakeInfoById } from '../../services/employmentModule.service';
import { IEmploymentIntakeInfo } from '../../store/employmentModule/employmentModal';
import { getFileNameFromUrl, isFileSizeValid } from '../../utils/fileHandler';

interface IEmploymentIntakeFormProps {
  clientId: string;
}

const EmploymentIntakeForm = (props: IEmploymentIntakeFormProps) => {
  const { clientId } = props;
  const [filesInfo, setFilesInfo] = useState<Array<any>>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [clientEmpInfo, setClientEmpInfo] = useState<any>(null);
  const [clientInfo, setClientInfo] = useState<any>(null);
  const [uploadedFiles, setUploadedFiles] = useState<any>(clientEmpInfo?.urls || []);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const schema = yup.object().shape({
    files: yup.mixed().test('fileSize', 'File Size is too large', (value: any) => isFileSizeValid(value)),
    checkResume: yup.boolean().required(),
    checkEmailAccess: yup.boolean().required(),
    checkCertifications: yup.boolean().required(),
    checkPassport: yup.boolean().required(),
    checkEmploymentGap: yup.boolean().required(),
    gapDescription: yup.string().optional(),
  });

  const initialValues: any = {
    files: [],
    checkResume: clientEmpInfo?.checkResume || false,
    checkEmailAccess: clientEmpInfo?.checkEmailAccess || false,
    checkCertifications: clientEmpInfo?.checkCertifications || false,
    checkPassport: clientEmpInfo?.checkPassport || false,
    checkEmploymentGap: clientEmpInfo?.checkEmploymentGap || false,
    gapDescription: clientEmpInfo?.gapDescription || '',
  };

  const handleSubmit = async (values: any, { setSubmitting }: FormikHelpers<any>) => {
    setSubmitting(true);
    const urls: any = await uploadFiles(filesInfo);
    const finalUrls = [...urls, ...uploadedFiles] || [];
    const payload: IEmploymentIntakeInfo = {
      id: clientId,
      urls: finalUrls,
      checkResume: values.checkResume,
      checkEmailAccess: values.checkEmailAccess,
      checkCertifications: values.checkCertifications,
      checkPassport: values.checkPassport,
      checkEmploymentGap: values.checkEmploymentGap,
    };
    if (values.checkEmploymentGap && values.gapDescription) {
      payload.gapDescription = values.gapDescription;
    }
    await addEmploymentIntakeInfo(payload);
    setFilesInfo([]);
    setSubmitting(false);
    getEmploymentIntakeData();
  };

  const getEmploymentIntakeData = async () => {
    const unsubscribe = getEmploymentIntakeInfoById(clientId, (data: any) => {
      setClientEmpInfo(data);
      setUploadedFiles(data?.urls || []);
    });
    return () => {
      unsubscribe();
    };
  };

  const getClientInfo = async () => {
    if (clientId) {
      const data = await getClientInfoById(clientId);
      setClientInfo(data);
    }
  };

  useEffect(() => {
    (async () => {
      await getClientInfo();
      await getEmploymentIntakeData();
    })();
    // eslint-disable-next-line
  }, []);

  const uploadedFileViewHandler = (url: any, index: number) => {
    return (
      <Grid key={index.toString()} item xs={12} lg={12} mb={2}>
        <Box
          sx={{
            height: 'auto',
            borderRadius: 10,
            backgroundColor: COLORS.palette.borderColor,
            border: `1px dashed ${COLORS.theme.primaryColor}`,
          }}>
          <Grid container alignItems={'center'} spacing={2} px={1}>
            <Grid item xs={8} lg={8}>
              <Typography variant='caption' align='center' sx={{ fontWeight: 'bold' }}>
                {getFileNameFromUrl(url, clientInfo)}
              </Typography>
            </Grid>

            <Grid item xs={4} lg={4}>
              <Box display='flex' justifyContent='flex-end' alignItems='center' gap='16px'>
                <Tooltip title='View' arrow>
                  <IconButton
                    type='submit'
                    color='success'
                    onClick={() => {
                      window.open(url);
                    }}>
                    <VisibilityOutlined />
                  </IconButton>
                </Tooltip>

                <Tooltip title='Delete' arrow>
                  <IconButton
                    size='small'
                    color='error'
                    onClick={() => {
                      if (uploadedFiles.length) {
                        const filteredFiles = uploadedFiles.filter((file: any) => file !== url);
                        const finalFilesList = filteredFiles?.length ? [...filteredFiles] : [];
                        setUploadedFiles(finalFilesList);
                      }
                    }}>
                    <Clear />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    );
  };

  return (
    <Box sx={{ background: COLORS.card.cardBg }} borderRadius={'10px'} p={2}>
      <Typography mb={1} variant='h6'>
        Employment Intake
      </Typography>
      <Box py={2}>
        <Divider variant='fullWidth' light={true} />
      </Box>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schema}
        enableReinitialize={true}
        validateOnMount={true}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isValid,
          isSubmitting,
        }) => (
          <Box>
            <Grid container alignItems={'center'}>
              <Grid
                item
                xs={12}
                lg={12}
                mt={1}
                mb={3}
                alignItems={'center'}
                justifyContent={'space-between'}
                display={'flex'}>
                <Typography ml={1} variant='h6'>
                  Upload Documents
                </Typography>

                <Tooltip title='Attach Documents' arrow>
                  <IconButton color='primary' size='large' onClick={handleClick}>
                    <AddCircleOutline />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item xs={12} lg={12}>
                {errors.files ? (
                  <p style={{ color: COLORS.result.error, fontSize: '12px', textAlign: 'center' }}>
                    {errors.files.toString()}
                  </p>
                ) : null}
              </Grid>
            </Grid>

            <Grid container alignItems={'center'}>
              {uploadedFiles?.length
                ? uploadedFiles.map((url: any, index: number) => uploadedFileViewHandler(url, index))
                : null}

              {filesInfo?.length
                ? filesInfo.map((fileDetails: any, index: number) => (
                    <Grid key={index.toString()} item xs={12} lg={12} mb={1}>
                      <Box
                        sx={{
                          height: 'auto',
                          borderRadius: 10,
                          backgroundColor: COLORS.palette.borderColor,
                          border: `1px dashed ${COLORS.theme.primaryColor}`,
                        }}>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            lg={12}
                            px={1}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            display={'flex'}>
                            <Typography variant='caption' align='center' sx={{ fontWeight: 'bold' }}>
                              {fileDetails?.name}
                            </Typography>

                            <Tooltip title='Delete' arrow>
                              <IconButton
                                size='small'
                                color='error'
                                onClick={() => {
                                  if (filesInfo.length) {
                                    const filteredFiles = filesInfo.filter(
                                      (file: any) => file.name !== fileDetails?.name
                                    );
                                    const finalFilesList = filteredFiles?.length ? [...filteredFiles] : [];
                                    setFilesInfo(finalFilesList);
                                    setFieldValue('files', finalFilesList?.length ? finalFilesList : '');
                                  }
                                }}>
                                <Clear />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  ))
                : null}
            </Grid>

            <Grid container>
              <Grid item xs={12} lg={12}>
                <input
                  name='files'
                  type='file'
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={(event: any) => {
                    {
                      const { files } = event.target;
                      if (files?.length) {
                        setFilesInfo((prevState: any[]) => [...prevState, ...files]);
                        setFieldValue('files', values.files.concat(files));
                      }
                    }
                  }}
                  multiple
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} lg={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='checkResume'
                      checked={values.checkResume}
                      onChange={(event) => {
                        setFieldValue('checkResume', event.target.checked);
                      }}
                    />
                  }
                  label='Is Resume Included?'
                />
              </Grid>

              <Grid item xs={12} lg={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='checkCertifications'
                      checked={values.checkCertifications}
                      onChange={(event) => {
                        setFieldValue('checkCertifications', event.target.checked);
                      }}
                    />
                  }
                  label='Check Certifications?'
                />
              </Grid>

              <Grid item xs={12} lg={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='checkPassport'
                      checked={values.checkPassport}
                      onChange={(event) => {
                        setFieldValue('checkPassport', event.target.checked);
                      }}
                    />
                  }
                  label='Check Passport?'
                />
              </Grid>

              <Grid item xs={12} lg={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='checkEmailAccess'
                      checked={values.checkEmailAccess}
                      onChange={(event) => {
                        setFieldValue('checkEmailAccess', event.target.checked);
                      }}
                    />
                  }
                  label='Do you have email access?'
                />
              </Grid>

              <Grid item xs={12} lg={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='checkEmploymentGap'
                      checked={values.checkEmploymentGap}
                      onChange={(event) => {
                        setFieldValue('checkEmploymentGap', event.target.checked);
                      }}
                    />
                  }
                  label='Do you have employment gaps?'
                />
              </Grid>

              {values.checkEmploymentGap ? (
                <Grid item xs={12} lg={12} my={2}>
                  <TextField
                    fullWidth
                    name='gapDescription'
                    type='text'
                    label='Describe the Gaps in Employment'
                    value={values.gapDescription}
                    rows={4}
                    multiline
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.gapDescription && Boolean(errors.gapDescription)}
                    helperText={touched.gapDescription && Boolean(errors.gapDescription)}
                  />
                </Grid>
              ) : null}
            </Grid>

            <Box display='flex' justifyContent='center' mt={2}>
              <LoadingButton
                type='submit'
                variant='contained'
                color='primary'
                loading={isSubmitting}
                disabled={!isValid}
                size='medium'
                label='Submit'
                styles={{ textTransform: 'none' }}
                onClick={() => {
                  handleSubmit();
                }}
              />
            </Box>
          </Box>
        )}
      </Formik>
    </Box>
  );
};
export default EmploymentIntakeForm;
