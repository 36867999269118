import { Grid, Typography } from '@mui/material';
import { ClientInfoForm } from '../../../components/ClientInfoForm/ClientInfoForm';
import { useAppSelector } from '../../../store/store';
import { useEffect, useState } from 'react';
import { Loader } from '../../../components/Loader/Loader';
import { getClientInfoById } from '../../../services/client.service';

export default function ClientAccountInfoPage() {
  const user: any = useAppSelector((state: any) => state.user.user);
  const [isClientUpdated, setIsClientUpdated] = useState<boolean>(false);
  const [clientInfo, setClientInfo] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (user?.id) {
      (async () => {
        setLoading(true);
        const info = await getClientInfoById(user?.id || '');
        setClientInfo(info);
        setLoading(false);
      })();
    }
  }, [user]);

  if (loading) {
    return <Loader />;
  }
  return (
    <Grid container>
      <Typography variant='h4' sx={{ m: 5 }}>
        Account Info
      </Typography>
      {clientInfo && (
        <Grid item sx={{ mx: 5, my: 2 }}>
          <ClientInfoForm client={clientInfo} onSubmit={() => setIsClientUpdated(!isClientUpdated)} />;
        </Grid>
      )}
    </Grid>
  );
}
